const BallotSummaryComponent = {
  templateUrl: '/app/assets/component/order/ballot/component.html',
  controller: BallotSummaryController,
  bindings: {
    ballot: '<',
    withdraw: '=',
    edit: '=',
    buy: '=',
  },
};

export default BallotSummaryComponent;

BallotSummaryController.$inject = ['ModalService'];
export function BallotSummaryController(ModalService) {
  const vm = this;

  vm.$onInit = onInit;
  vm.toggleList = toggleList;
  vm.confirmWithdraw = withdraw;

  vm.listOpen = false;

  function onInit() {
    const isWaiting = ['awaiting ballot', 'pending'].includes(vm.ballot.state);

    vm.showNext =
      vm.ballot.hasOwnProperty('ballot_date') &&
      (isWaiting || vm.ballot.state == 'buy now');
    vm.nextText = isWaiting ? 'Next ballot' : 'Pay by';
    vm.dateString = vm.ballot.Product.ProductVariant.start_date;
    vm.dateString += vm.ballot.Product.ProductVariant.end_date
      ? `-${vm.ballot.Product.ProductVariant.end_date}`
      : '';

    vm.ticketString = `${vm.myOrders.total_tickets} ticket${
      (vm.ballot.total_tickets > 1 && 's') || ''
    }`;

    vm.canWithdraw = ['buy now', 'awaiting ballot', 'won'].includes(
      vm.ballot.state
    );
  }

  function toggleList() {
    vm.listOpen = !vm.listOpen;
  }

  function withdraw() {
    ModalService.showModal({
      templateUrl: '/app/view/users/ballot-withdraw.html',
      controller: 'DeleteController',
      controllerAs: 'modal',
      inputs: {
        type: 'withdraw',
      },
    }).then(modal => {
      modal.close.then(
        response => response === true && vm.withdraw(vm.ballot.id)
      );
    });
  }
}
