import './style.scss';

import template from './component.html';

const ProductSummaryComponent = {
  template,
  controller: ProductSummaryController,
  bindings: {
    product: '<',
  },
};

export default ProductSummaryComponent;

export function ProductSummaryController() {
  const vm = this;

  vm.$onInit = onInit;

  function onInit() {
    vm.productString = `${vm.product.order_details.itemCount} membership${
      (vm.product.order_details.itemCount > 1 && 's') || ''
    }`;
  }
}
