import environment from '../../../../environment';

mainMenuController.$inject = ['$http', 'LeisureJsonApi'];

export default function mainMenuController($http) {
  const vm = this;

  vm.menu = [];

  $http
    .get(`${environment.settings.registeredAwsUrl}/categories`)
    .then(({data}) => angular.copy(data.data, vm.menu));
}
