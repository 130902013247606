// Module
var code = `<div
  class="panel"
  data-ng-class="{'active-panel' : \$ctrl.activePanel == 'options'}"
>
  <div class="panel-heading">
    <h4 class="panel-title">
      {{ \$ctrl.variant.title}}
      <span
        class="fa fa-check-square-o"
        data-ng-class="{'hide' : !checkout.status.billing()}"
      ></span>
    </h4>
  </div>
  <div class="panel-body">
    <section data-ng-form="\$ctrl.pickOptionsForm">
      <section class="prices">
        <div class="row">
          <div class="small-12 columns">
            <div class="row">
              <div class="small-4 columns">
                <label class="right inline">
                  Quantity at {{ \$ctrl.variant.price/100 | currency : "&pound;"
                  : 2 }}
                </label>
              </div>
              <div class="small-8 columns">
                <input
                  type="number"
                  ng-pattern="/^\\d+\$/"
                  data-ng-model="\$ctrl.items.adultPartner.amount"
                  min="0"
                  data-ng-change="\$ctrl.updateTotal()"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row" data-ng-if="\$ctrl.variant.delivery_method == 'post'">
          <div class="small-12 columns">
            <div class="row">
              <div class="small-4 columns">
                <label class="right inline">
                  Delivery at {{ \$ctrl.variant.delivery == 0 ? 'Free' :
                  (\$ctrl.variant.delivery/100 | currency : "&pound;" : 2) }}
                </label>
              </div>
              <div class="small-8 columns">
                <div class="switch round">
                  <input
                    id="deliverySwitch"
                    type="checkbox"
                    data-ng-model="\$ctrl.items.delivery.amount"
                    data-ng-change="\$ctrl.updateTotal()"
                  />
                  <label for="deliverySwitch"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <p
      data-ng-if="\$ctrl.variant.tickets_per_partner"
      data-ng-class="{'error' : \$ctrl.total.items > \$ctrl.variant.tickets_per_partner, 'info' : \$ctrl.total.items <= \$ctrl.variant.tickets_per_partner}"
    >
      You are only allowed {{ \$ctrl.variant.tickets_per_partner }} item{{
      \$ctrl.variant.tickets_per_partner > 1 && 's' || '' }} per Partner
    </p>

    <p
      class="panel callout warning"
      data-ng-show="\$ctrl.showHurry || \$ctrl.total.items > \$ctrl.variant.stock_quantity"
    >
      There {{ \$ctrl.variant.stock_quantity == 1 && 'is' || 'are' }} only {{
      \$ctrl.variant.stock_quantity }} left to purchase, be quick before they're
      all gone!
    </p>

    <jlp-terms-component
      terms-url="\$ctrl.tsAndCs"
      on-agree="\$ctrl.terms = true"
      on-disagree="\$ctrl.terms = false"
      close="\$ctrl.close()"
    ></jlp-terms-component>

    <div class="full-width">
      <section class="price">
        {{ \$ctrl.total.cost/100 | currency : '&pound;'}}
      </section>
      <button
        class="button accordion-toggle"
        data-ng-click="\$ctrl.addOptions()"
        data-ng-disabled="\$ctrl.disabled()"
      >
        Add Items
      </button>
    </div>
  </div>
</div>
`;
// Exports
export default code;