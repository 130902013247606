import template from './component.html';
import environment from '../../../../environment';

const OrdersListComponent = {
  template,
  controller: OrdersListController,
};

export default OrdersListComponent;

OrdersListController.$inject = [
  '$http',
  'LeisureJsonApi',
  'ModalService',
  'MessageService',
];
export function OrdersListController($http, JsonApi, ModalService) {
  /**
   * View model
   */
  const vm = this;
  /**
   * View Variables
   */
  vm.tabs = ['all', 'awaiting ballot', 'purchased', 'won', 'expired', 'ended'];
  vm.activeTab = 'all';

  /**
   * View functions
   */
  vm.setTab = setTab;
  vm.purchase = purchase;
  vm.filter = filter;
  vm.withdraw = withdraw;
  vm.editBallotEntry = editBallotEntry;

  vm.$onInit = () => {
    $http
      .get(`${environment.settings.registeredAwsUrl}/purchases`)
      .then(response => (vm.myOrders = response.data.data.orders));
  };

  /**
   * Randommly add a ticket to some f the ticket orders
   * to be replced when a real end point exists
   */
  // function loadTicketPdfs() {
  //   Promise.resolve()
  //     .then(() => {
  //       const endpoint = `/tickets`;

  //       return $http
  //         .get(`${awsUrl}${endpoint}`)
  //         .then(({data}) => data)
  //         .catch(error => showErrorMessage(error, 'Could not load tickets.'));
  //     })
  //     .then(pdfTickets => {
  //       vm.myOrders.forEach(order => {
  //         const ticketData = pdfTickets.filter(
  //           ticket => ticket.orderId == order.id
  //         )[0];
  //         const ticketUrls =
  //           typeof ticketData !== 'undefined'
  //             ? ticketData.ticketUrls.map(ticketUrl => ticketUrl)
  //             : null;
  //         order.ticketPdf = ticketUrls;
  //         order.ticketString = `${order.order_details.ticketCount} ticket${
  //           (order.order_details.ticketCount > 1 && 's') || ''
  //         }`;
  //       });
  //     });
  // }
  /**
   * Protected/ Private functions
   */
  function purchase(entry) {
    const tickets = entry.Product.ProductVariant.prices.reduce(
      (response, price) => {
        response[price.slug] = {
          label: price.label,
          amount: entry.ballot_entry[price.slug],
        };

        return response;
      },
      {}
    );

    // show  modal
    ModalService.showModal({
      templateUrl: '/app/assets/component/checkout/component.html',
      controller: 'CheckoutModalController',
      controllerAs: 'checkout',
      bodyClass: 'modal-open',
      inputs: {
        product: entry.Product,
        variant: entry.Product.ProductVariant,
        tickets,
        ballotEntryId: entry.id,
      },
    });
  }

  function setTab(tab) {
    vm.activeTab = tab;
  }

  function filter(ballotEntry) {
    const map = {
      'awaiting ballot': 'awaiting ballot',
      purchased: 'purchased',
      won: 'buy now',
      expired: 'expired',
      ended: 'ended',
    };

    if (vm.activeTab === 'all') {
      return true;
    } else {
      return map[vm.activeTab] === ballotEntry.state;
    }
  }

  function editBallotEntry(ballotEntry) {
    ModalService.showModal({
      templateUrl: 'app/view/modals/ballot-pick-options.html',
      controller: 'ProductPickOptionsController',
      controllerAs: 'modal',
      bodyClass: 'modal-open',
      inputs: {
        product: ballotEntry.Product,
        variant: ballotEntry.Product.ProductVariant,
        tickets: ballotEntry.ballot_entry,
      },
    }).then(modal => {
      modal.close.then(ticketEntry => {
        ticketEntry &&
          $http
            .put(
              JsonApi.buildUrl('BallotEntries/' + ballotEntry.id),
              ticketEntry
            )
            .then(response => {
              JsonApi.handleResponse(response, refresh);
            });
      });
    });
  }

  function withdraw(entryId) {
    $http
      .delete(JsonApi.buildUrl('/BallotEntries/' + entryId))
      .then(response => {
        JsonApi.handleResponse(response, refresh);
      });
  }

  function refresh() {
    $http.get(JsonApi.buildUrl('/BallotEntries')).then(response => {
      JsonApi.handleResponse(response, replenishBallots);
    });
  }

  function replenishBallots(data) {
    angular.copy(data.BallotEntry, vm.myOrders);
  }

  // function showErrorMessage(error, defaultMessage) {
  //   const errorMessage = !debug
  //     ? defaultMessage
  //     : `${defaultMessage}\n\n${JSON.stringify(error, null, 2)}`;

  //   MessageService.error(errorMessage);
  // }
}
