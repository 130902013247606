import environment from '../../../../environment';
import {receiveEmails, tsAndCs} from '../../../../environment/constants';

const defaultUser = {
    employee_number: '',
    features: {},
  },
  defaultErrors = {};

registerController.$inject = [
  'AuthToken',
  '$state',
  '$http',
  'LeisureJsonApi',
  'MessageService',
];

export default function registerController(
  AuthToken,
  $state,
  $http,
  JsonApi,
  Message
) {
  /** ============================================ *
   * View Model
   * ============================================= */
  const vm = this;

  /** ============================================ *
   * View Variables
   * ============================================= */

  vm.WEBSITE_TS_AND_CS = tsAndCs.website;
  vm.RECEIVE_EMAILS_TEXT = receiveEmails.text;
  vm.RECEIVE_EMAILS_LABEL = receiveEmails.label;
  vm.user = {...defaultUser};
  vm.errors = {};
  vm.display = {
    errors: false,
  };
  vm.panel = {
    active: 'personal',
  };

  /** ============================================ *
   * View Functions
   * ============================================= */
  vm.submit = submit;
  vm.errorFields = errorFields;
  vm.setPanel = setPanel;
  vm.hasError = hasError;

  /** ============================================ *
   * Protected/Private Functions
   * ============================================= */

  function hasError(field) {
    return vm.errors.hasOwnProperty(field);
  }

  function setPanel(panel) {
    if (vm.panel.active == panel) {
      vm.panel.active = null;
    } else {
      vm.panel.active = panel;
    }
  }

  function errorFields() {
    return Object.keys(vm.errors);
  }

  /**
   * Do some cheaks on passsword and employee umber,
   * not in browser s a user should not know what makes a valid employee number
   *
   * @returns {boolean}
   */
  function validates() {
    resetErrors();

    const errors = {
      password: "Your passwords don't match.",
      employee_number: 'Your employee number is incorrect.',
    };

    let valid = true,
      fields = {
        employee_number:
          vm.user.employee_number.length === 8 &&
          !isNaN(vm.user.employee_number),
        password:
          vm.user.password && vm.user.password == vm.user.passwordConfirm,
      };

    // do some error messaging
    angular.forEach(fields, (isValid, field) => {
      if (!isValid) {
        valid = false;
        Message.error(errors[field]);
        vm.errors[field] = true;
      }
    });

    return valid;
  }

  /**
   * Start from fresh no errors and hidden div
   */
  function resetErrors() {
    vm.display.errors = false;

    angular.copy(defaultErrors, vm.errors);
  }

  /**
   * validate and deal with the response from promise
   */
  function submit() {
    if (validates()) {
      pass();
    } else {
      fails();
    }
  }

  /**
   * JS validation has passed, now onto the php and real validation
   */
  function pass() {
    AuthToken.set();

    $http.post(`${environment.settings.unregisteredAwsUrl}/register`, {User: vm.user}).then(response => {
      JsonApi.handleResponse(response, registered, fails);
    });
  }

  /**
   * Turn on error messages
   * turn off loading
   */
  function fails() {
    vm.display.errors = true;
  }

  /**
   * Deal with the response from server
   *
   * If a user is successfully registered forward them to the login page
   * Else display the errors sent back
   *
   * @param data
   */
  function registered() {
    $state.go('Guest.Login', {
      email: vm.user.email,
      name: `${vm.user.first_name} ${vm.user.last_name}`,
    });
  }
}
