import './terms-component.scss';

import {zdApiUrl} from '../../../../environment/constants';
import TermsModal from './terms-modal';

const TermsComponent = {
  templateUrl: '/app/assets/component/checkout/terms/terms-component.html',
  controller: TermsController,
  bindings: {
    termsUrl: '<',
    onAgree: '&',
    onDisagree: '&',
    close: '&',
  },
};
export default TermsComponent;

TermsComponent.$inject = [
  '$http',
  'ModalService',
  'MessageService',
  'StorageService',
  'UserService',
];

export function TermsController(
  $http,
  ModalService,
  MessageService,
  StorageService,
  UserService
) {
  const ctrl = this;

  ctrl.loading = false;
  ctrl.terms = null;
  ctrl.hasReadTerms = false;
  ctrl.hasAgreedTerms = false;

  ctrl.$onInit = onInit;
  ctrl.showTerms = showTerms;

  let termsId;

  ctrl.onAgreeChange = () =>
    (ctrl.hasAgreedTerms && ctrl.onAgree()) || ctrl.onDisagree();

  function onInit() {
    const {termsUrl} = ctrl;
    const rx = /(\d+)(?:-[\w-]+)?$/g;
    [, termsId] = rx.exec(termsUrl) || [];

    const endpoint = `${zdApiUrl}/help_center/articles/${termsId}`;

    ctrl.loading = true;

    $http
      .get(endpoint)
      .then(({data: {article: terms}}) => {
        ctrl.terms = terms;
        const {id: userId} = UserService.user;
        const {edited_at} = terms;

        ctrl.hasReadTerms =
          StorageService.get(`${userId}-ts-and-cs-${termsId}`) === edited_at;
      })
      .catch(({data}) => {
        const {message = `Could not load terms & conditions.`} = data || {};

        MessageService.error(message);
        ctrl.close();
      })
      .finally(() => (ctrl.loading = false));
  }

  function showTerms() {
    ModalService.showModal({
      templateUrl: '/app/assets/component/checkout/terms/terms-modal.html',
      bodyClass: 'modal-open',
      controller: TermsModal,
      controllerAs: '$ctrl',
      inputs: {terms: ctrl.terms},
    }).then(modal => {
      modal.close.then(({hasAgreed = false} = {}) => {
        ctrl.hasReadTerms = hasAgreed;
        ctrl.hasAgreedTerms = hasAgreed;

        const {id: userId} = UserService.user;
        const {edited_at} = ctrl.terms;

        StorageService.set(`${userId}-ts-and-cs-${termsId}`, edited_at);

        ctrl.onAgreeChange();
      });
    });
  }
}
