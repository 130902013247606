import angular from 'angular';

import CardCarouselComponent from './card-carousel-component';
import CarouselController from './carousel-controller';
import FullWidthHeroComponent from './full-width-hero-component';
import HalfWidthHeroComponent from './half-width-hero-component';
import HeroCarouselComponent from './hero-carousel-component';
import ImageCarouselComponent from './image-carousel-component';
import StaticCarouselComponent from './static-carousel-component';
import VideoModal from './video-modal';

export default angular
  .module('PartnerChoice.user.carousel', [])
  .controller('CarouselController', CarouselController)
  .controller('videoModalController', VideoModal)
  .component('jlpFullWidthHeroComponent', FullWidthHeroComponent)
  .component('jlpHalfWidthHeroComponent', HalfWidthHeroComponent)
  .component('jlpHeroCarousel', HeroCarouselComponent)
  .component('jlpCardCarousel', CardCarouselComponent)
  .component('jlpStaticCarousel', StaticCarouselComponent)
  .component('jlpImageCarousel', ImageCarouselComponent);
