import angular from 'angular';

import forgottenController from './forgotten-controller';
import guestPageController from './guest-page-controller';
import loginController from './login-controller';
import logoutController from './logout-controller';
import registerController from './register-controller';
import resetController from './reset-controller';

export default angular
  .module('PartnerChoice.guest.controller', [])
  .controller('ForgottenController', forgottenController)
  .controller('GuestPageController', guestPageController)
  .controller('LoginController', loginController)
  .controller('LogoutController', logoutController)
  .controller('RegisterController', registerController)
  .controller('ResetController', resetController);
