import './_style.scss';

import {statusTypes} from './constants';

const OrderSummaryComponent = {
  templateUrl: '/app/assets/component/order-summary/component.html',
  controller: OrderSummaryController,
};

export default OrderSummaryComponent;

OrderSummaryController.$inject = [
  '$stateParams',
  '$http',
  'LeisureJsonApi',
  'DataLayerService',
];

export function OrderSummaryController(
  $stateParams,
  $http,
  JsonApi,
  DataLayer
) {
  const vm = this;

  vm.loading = true;
  vm.status = null;
  vm.orderId = null;
  vm.mailTo = null;
  vm.$onInit = $onInit;

  function $onInit() {
    const {orderId, status} = $stateParams;

    vm.orderId = orderId;
    vm.statusType = status;
    const isSuccess = status === 'success';

    $http
      .get(JsonApi.buildUrl(`v2/registered/orders/${orderId}`, {status}))
      .then(response =>
        JsonApi.handleResponse(response, order => {
          vm.order = order;
          const {uuid, item, total} = order || {};
          isSuccess && DataLayer.logPurchase({orderId: uuid, item, total});
        })
      )
      .catch(response => {
        const {status: httpStatus, statusText} = response || {};

        const email = 'partnerchoice@johnlewis.co.uk';
        const subject = encodeURIComponent(
          `[Order Status Error] Order ID: ${vm.orderId}`
        );
        const body = encodeURIComponent(`
--------------------------------------------------
ERROR DETAILS
--------------------------------------------------
Order ID:     ${orderId}
Order status: ${status}
HTTP status:  ${httpStatus} - ${statusText}
--------------------------------------------------
`);
        vm.statusType = 'statusError';
        vm.mailTo = `mailto:${email}?subject=${subject}&body=${body}`;
      })
      .finally(() => {
        const statusType =
          Object.keys(statusTypes).find(key => key === vm.statusType) ||
          'statusError';

        vm.status = statusTypes[statusType];
        vm.loading = false;
      });
  }
}
