/**
 * Date to iso filter
 *
 * Use this to change a mysql date string into an isostring for use with angular date filter
 * Can take a date time or just date string
 *
 * date time = '2014-12-25 00:00:00'
 * date      = '2014-12-25';
 *
 * eg
 * javascript
 * vm.mysqlDate = '2014-12-25 12:00:00';
 *
 * template
 * {{ mysqlDate | dateToIso | date 'Your format' }}
 *
 *
 */
export default function dateToIso() {
  return input => {
    if (!input) {
      return null;
    }

    const t = input.split(/[- :]/);

    return new Date(
      Date.UTC(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0)
    ).toISOString();
  };
}
