/**
 * Explanation of the flexbox slider utilising ordering to move objects
 *
 * make the items appear to not move when in fact the have shifted
 * the carousel object moves one item to the right, while items move one item to the left
 * then class is applied to transition the transform back one item to the left, giving the slide
 *
 * Set the order of the items               - jumps items to left
 * remove the 'is-set' class from carousel  - jumps container to right
 * addClass 'is-set' to carousel            - transitions container slide to left
 */
import getVideoId from 'get-video-id';

import template from './video.html';

CarouselController.$inject = ['$element', '$state', '$timeout', 'ModalService'];

export default function CarouselController(
  $element,
  $state,
  $timeout,
  ModalService
) {
  const step = 4750,
    delay = 250;

  let timer = null;
  let activeSlide;
  const vm = this;

  vm.$onChanges = onChanges;
  vm.next = nextSlide;
  vm.previous = previousSlide;
  vm.goToPage = goToPage;
  vm.showVideo = showVideo;
  vm.onThumbnailClick = onThumbnailClick;
  vm.loading = false;
  vm.isMobile = false;

  /**
   * Pass through to allow swipes and taps to work in harmony together
   *
   * @param state
   * @param config
   */
  function goToPage(state, config) {
    $state.go(state, config);
  }

  function onChanges({items}) {
    const {currentValue = []} = items || {};
    const isCarousel = currentValue.length > 1;

    Promise.resolve()
      .then(() => (vm.loading = true))
      .then(() => setVideo())
      .then(() => {
        if (!isCarousel) {
          return;
        }

        setTheOrder();

        $element.children().removeClass('static');

        !!timer && $timeout.cancel(timer);
      })
      .finally(() => (vm.loading = false));
  }

  function nextSlide(manual) {
    !!timer && $timeout.cancel(timer);

    moveForward();

    !manual && (timer = $timeout(nextSlide, step));
  }

  function previousSlide(manual) {
    !!timer && $timeout.cancel(timer);

    moveBackward();

    !manual && (timer = $timeout(nextSlide, step));
  }

  function moveForward() {
    const $el = $element.children('.carousel');

    $el.removeClass('is-set').removeClass('is-reversing');

    vm.items.forEach(item => {
      item.carouselOrder--;

      item.carouselOrder < 0 && (item.carouselOrder += vm.items.length);

      item.carouselOrder === 2 && (activeSlide = item);
    });

    $timeout(() => {
      $el.addClass('is-set');
    });
  }

  function moveBackward() {
    $element
      .children('.carousel')
      .removeClass('is-set')
      .addClass('is-reversing');

    vm.items.forEach(item => {
      item.carouselOrder++;

      item.carouselOrder >= vm.items.length &&
        (item.carouselOrder -= vm.items.length);

      item.carouselOrder === 2 && (activeSlide = item);
    });

    $timeout(() => {
      $element.children('.carousel').addClass('is-set');
    }, delay);
  }

  function setTheOrder() {
    let index = 0;
    // allow for large screen to continue to work.
    vm.thumbnails = vm.items;

    if (!$element.hasClass('no-duplicate')) {
      vm.items.length > 1 &&
        (vm.items = vm.items.concat(angular.copy(vm.items)));
      index = 2;
    }

    vm.items.forEach(item => {
      item.carouselOrder = index;

      index++;

      index >= vm.items.length && (index -= vm.items.length);
    });
  }

  function onThumbnailClick(thumbnail) {
    const {id: thumbnailId} = thumbnail;
    const {id: activeSlideId} = activeSlide || {id: null};
    if (thumbnailId !== activeSlideId) {
      nextSlide(true);
      $timeout(() => {
        onThumbnailClick(thumbnail);
      }, 50);
    }
  }

  function setVideo() {
    const item = vm.items ? vm.items.find(({video}) => video) : null;
    if (!item) {
      return;
    }

    try {
      const {video: url} = item;
      const {id, service} = getVideoId(url) || {};

      switch (service) {
        case 'youtube':
          return setYouTubeVideo(item, id);
        case 'vimeo':
          return setVimeoVideo(item);
        default:
          removeItem(item);
          return Promise.resolve();
      }
    } catch (error) {
      return Promise.resolve();
    }
  }

  function setYouTubeVideo(item, id) {
    const title = 'YouTube Video';
    const src = `https://www.youtube.com/embed/${id}?autoplay=1`;
    const thumbnail = `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;

    item.video = {
      title,
      src,
      thumbnail,
    };

    return Promise.resolve();
  }
  function setVimeoVideo(item) {
    const {video: url} = item;
    const endpoint = `https://vimeo.com/api/oembed.json?url=${url}&autoplay=1`;

    return fetch(endpoint)
      .then(response => response.json())
      .then(({title = 'Vimeo Video', html = '', thumbnail_url: thumbnail}) => {
        const matcher = /<iframe.*?src="(.*?)"[^>]+>/g;
        const [, src] = matcher.exec(html);

        item.video = {
          title,
          src,
          thumbnail,
        };
      })
      .catch(() => removeItem(item));
  }

  function removeItem(item) {
    const index = vm.items.indexOf(item);
    index && vm.items.splice(index, 1);
  }

  function showVideo(video) {
    ModalService.showModal({
      template,
      controller: 'videoModalController',
      controllerAs: 'Video',
      bodyClass: 'modal-open',
      inputs: {
        video,
      },
    });
  }
}
