import './_styles.scss';

import environment from '../../../../environment';

const {debug} = environment.settings;

const CheckoutCardComponent = {
  templateUrl: '/app/assets/component/checkout/card/component.html',
  controller: CheckoutCardController,
  bindings: {
    activePanel: '<',
  },
};

export default CheckoutCardComponent;

export function CheckoutCardController() {
  const vm = this;

  vm.debug = debug;
}
