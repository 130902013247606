import './feature-button-component.scss';

import template from '../modal/feature-modal.html';
import FeatureModalController from '../modal/feature-modal-controller';

const FeatureButtonComponent = {
  templateUrl:
    '/app/assets/component/feature/button/feature-button-component.html',
  controller: FeatureButtonController,
  replace: true,
};
export default FeatureButtonComponent;

FeatureButtonController.$inject = ['FeatureService', 'ModalService'];

export function FeatureButtonController(service, modalService) {
  const vm = this;

  vm.features = service.features;
  vm.toggleFeatures = () => {
    modalService.closeModals();

    modalService
      .showModal({
        template,
        controller: FeatureModalController,
        controllerAs: 'modal',
        bodyClass: 'modal-open',
      })
      .then(modal => {
        vm.isVisible = true;
        modal.close.then(() => (vm.isVisible = false));
      });
  };
  vm.isVisible = false;
}
