import './component.scss';

const ProductListComponent = {
  templateUrl: '/app/assets/component/product-list/component.html',
  controller: ProductListController,
  bindings: {
    items: '<',
    registerProductsOnPage: '&?',
  },
};

export default ProductListComponent;

ProductListController.$inject = [
  '$window',
  '$document',
  'ProductRatingService',
];
export function ProductListController(
  $window,
  $document,
  ProductRatingService
) {
  let isScrolling;

  const vm = this;

  vm.$onInit = onInit;
  vm.$onDestroy = onDestroy;
  vm.productsOnPage = productsOnPage;

  function onInit() {
    $window.addEventListener('scroll', waitForChange, false);

    vm.registerProductsOnPage({
      handler: waitForChange,
    });
  }

  function onDestroy() {
    $window.removeEventListener('scroll', waitForChange);
  }

  function waitForChange() {
    // Clear our timeout throughout the scroll
    $window.clearTimeout(isScrolling);

    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(productsOnPage, 500);
  }

  function productsOnPage() {
    const visibleElements = vm.items
      .map(item => {
        const domItem = $document[0].querySelector(
          `[data-item-id="${item.id}"]`
        );

        return !!domItem && elementIsVisible(domItem) ? item.id : null;
      })
      .filter(i => i);

    ProductRatingService.showList(visibleElements);
  }

  function elementIsVisible(element) {
    const {offsetHeight: bottomNavHeight} = $document[0].querySelector(
      '.bottom-nav'
    );
    const {offsetHeight: topNavHeight} = $document[0].querySelector('.top-nav');
    const {pageYOffset, innerHeight} = $window;
    const {offsetTop: elementTop, offsetHeight} = element;

    const pageBottom = pageYOffset + innerHeight - bottomNavHeight;
    const pageTop = pageYOffset + topNavHeight;

    const elementBottom = elementTop + offsetHeight;

    const topHalfAboveBottom = elementTop + offsetHeight / 2 < pageBottom;
    const bottomHalfBelowTop = elementBottom - offsetHeight / 2 > pageTop;

    return topHalfAboveBottom && bottomHalfBelowTop;
  }
}
