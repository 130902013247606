// Module
var code = `<div id="fade"></div>
<div id="modal">
  <div class="modal-content">
    <div class="modal-container">
      <div class="header">
        <button type="button" class="close" data-ng-click="\$ctrl.close()">
          <span class="fa fa-fw fa-times"></span>
        </button>
        <h3>{{ \$ctrl.item.title }}</h3>
      </div>
      <div class="body">
        <div>
          <div data-ng-bind-html="\$ctrl.item.description"></div>
          <div
            data-ng-if="\$ctrl.item.file_type.indexOf('image') > -1 && !\$ctrl.item.thumbnails"
          >
            <img
              alt="{{ \$ctrl.item.file_title }}"
              data-ng-src="{{ '/product-extra-info-file/' + \$ctrl.item.id + '/' + \$ctrl.item.file_slug  }}"
            />
          </div>
          <div
            data-ng-if="\$ctrl.item.file_type.indexOf('image') == -1 && \$ctrl.item.file_title"
            class="download"
          >
            <a
              data-ng-href="/product-extra-info-file/{{ \$ctrl.item.id }}/{{ \$ctrl.item.file_slug }}"
              target="_self"
            >
              {{ \$ctrl.item.file_title }}
              <span></span>
            </a>
          </div>

          <jlp-image-carousel
            data-ng-if="\$ctrl.item.thumbnails"
            data-slug="\$ctrl.item.id"
            class="modal-carousel"
            data-items="\$ctrl.item.thumbnails"
          ></jlp-image-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
`;
// Exports
export default code;