import {BehaviorSubject} from 'rxjs';

import environment from '../../../../environment';
import {preferences} from '../../../../environment/constants';

const {adminUrl} = environment.settings;

userService.$inject = ['$http', 'LeisureJsonApi', 'zE'];

export default function userService($http, JsonApi, zE) {
  const userBehaviour = new BehaviorSubject(null);
  const service = {
    $user: userBehaviour.asObservable(),
    user: {},
    load,
    editUser,
    updatePreferences,
    editAddress,
    deleteAddress,
  };

  load();

  return service;

  function editUser(user) {
    if (user.hasOwnProperty('preferences')) {
      return updatePreferences(user.preferences);
    }

    const userSave = {};
    const fields = [
      'id',
      'first_name',
      'last_name',
      'email',
      'branch_number',
      'passwordCurrent',
      'password',
      'passwordConfirm',
    ];

    const featureFields = ['retired'];

    fields.forEach(field => {
      if (user.hasOwnProperty(field)) {
        userSave[field] = user[field];
      }
    });

    const {features} = userBehaviour.getValue();

    userSave.features = featureFields.reduce((_features, field) => {
      return user.hasOwnProperty(field)
        ? {..._features, [field]: user[field]}
        : _features;
    }, features);

    return $http
      .put(JsonApi.buildUrl(urlHandle()), userSave)
      .then(response => JsonApi.handleResponse(response, load));
  }

  function updatePreferences(userPreferences) {
    const url = urlHandle() + '/' + 'preferences';

    return $http
      .post(JsonApi.buildUrl(url), userPreferences)
      .then(response => JsonApi.handleResponse(response, load));
  }

  function editAddress(address, index) {
    if (index) {
      address['id'] = index;
      $http
        .put(JsonApi.buildUrl(urlHandle() + '/UserAddress/' + index), address)
        .then(response => {
          JsonApi.handleResponse(response, load);
        });
    } else {
      $http
        .post(JsonApi.buildUrl(urlHandle() + '/UserAddress'), address)
        .then(response => {
          JsonApi.handleResponse(response, load);
        });
    }
  }

  function deleteAddress(id) {
    $http
      .delete(JsonApi.buildUrl(urlHandle() + '/UserAddress/' + id))
      .then(response => {
        JsonApi.handleResponse(response, load);
      });
  }

  function load() {
    // load in the user model from php

    $http.get(JsonApi.buildUrl('Users')).then(response => {
    // $http.get(`${environment.settings.registeredAwsUrl}/user`).then(response => {
      JsonApi.handleResponse(response, data => {
        const {User: user} = data;
        user.preferences = preferences.reduce((response, preference) => {
          user.features.hasOwnProperty(preference) &&
          (response[preference] = true);
          return response;
        }, {});
        
        // delete user.features;
        
        //check for admin
        user.isAdmin = !!user.groups.filter(group => group.includes('admin'))
        .length;
        user.adminUrl = adminUrl;
        user.retired = !!user.features.retired;
        
        angular.copy(user, service.user);
        userBehaviour.next(user);

        const {
          first_name: firstName,
          last_name: lastName,
          email,
        } = service.user;

        zE('webWidget', 'identify', {
          name: `${firstName} ${lastName}`,
          email,
        });
        zE('webWidget', 'prefill', {
          name: {
            value: `${firstName} ${lastName}`,
            readOnly: true,
          },
          email: {
            value: email,
            readOnly: true,
          },
        });
      });
    });
  }

  function urlHandle() {
    return '/Users/' + service.user.id;
  }
}
