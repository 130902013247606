import './hero.scss';

const HeroCarouselComponent = {
  templateUrl: '/app/assets/component/carousel/hero.html',
  controller: 'CarouselController',
  bindings: {
    items: '<',
  },
};

export default HeroCarouselComponent;
