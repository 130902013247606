SessionStorageService.$inject = ['$window'];

export default function SessionStorageService($window) {
  const handleError = console.error;
  const {sessionStorage} = $window || {};

  return {set, get};

  function set(prop, value) {
    try {
      sessionStorage[prop] = JSON.stringify(value);
    } catch (error) {
      handleError(error);
    }
  }

  function get(prop) {
    try {
      return JSON.parse(sessionStorage[prop]);
    } catch (error) {
      handleError(error);
    }
  }
}
