let alerts = [],
  pause = 2000, // Reaction time
  delay = 100, // ms/per character
  timer;

MessageService.$inject = ['$timeout'];

export default function MessageService($timeout) {
  const service = {
    message: {
      running: false,
      current: null,
    },
    cancel: cancel,
    error: error,
    success: success,
    info: info,
  };

  return service;

  function error(message, close = false) {
    return addAlert(message, 'alert', close);
  }

  function success(message, close = false) {
    return addAlert(message, 'success', close);
  }

  function info(message, close = false) {
    return addAlert(message, 'info', close);
  }

  function alertTime(message) {
    return pause + message.length * delay;
  }

  function addAlert(message, status, close) {
    if (message) {
      alerts.push({message, status, close});

      !service.message.running && processAlerts();
    }
  }

  function cancel() {
    $timeout.cancel(timer);
    processAlerts();
  }

  function processAlerts() {
    let current = null,
      running = false;

    if (alerts.length) {
      current = alerts.shift();
      running = true;
    }

    $timeout(() => {
      service.message.current = current;

      service.message.running = running;

      !!current &&
        !current.close &&
        (timer = $timeout(
          () => processAlerts(),
          alertTime(service.message.current.message)
        ));
    });
  }
}
