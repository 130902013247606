import './terms-modal.scss';

TermsModal.$inject = ['close', 'terms'];

export default function TermsModal(close, terms) {
  const vm = this;

  vm.terms = terms;
  vm.close = close;

  vm.agreeToTerms = () => close({hasAgreed: true});
}
