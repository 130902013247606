import environment from '../../../environment';

awsApiService.$inject = [
  '$http',
  'LoginService',
  'AuthToken',
  '$injector',
  'Url',
  '$window',
  'LoginTokenService',
];

export default function awsApiService(
  $http,
  loginService,
  authTokenService,
  $injector,
  Url,
  $window,
  loginTokenService
) {
  let get = (...args) => request(() => $http.get(...args));
  let post = (...args) => request(() => $http.get(...args));

  const service = {
    get,
    post,
    buildAwsApiUrl,
    checkToken,
  };
  return service;

  function request(handler) {
    return checkToken()
      .catch(() => {
        loginService.logout();
        authTokenService.set();

        const currentState = $injector.get('$state').current;
        if (currentState.name !== 'Guest.Login') {
          Url.previous = $window.location.pathname;
          $injector.get('$state').go('Guest.Login');
        }
        return Promise.reject('Token check failed');
      })
      .then(handler);
  }

  function buildAwsApiUrl(awsUrl, action) {
    return `${awsUrl}/${environment.stage}/${action}`;
  }

  function checkToken() {
    if (loginTokenService.isTokenExpired()) {
      if (loginTokenService.isRefreshTokenExpired()) {
        return Promise.reject({
          status: 0,
          statusText: 'Refresh token has expired',
        });
      }
      return $http
        .post(`${environment.settings.loginAwsUrl}/token-refresh`, {
          refreshToken: loginTokenService.getRefreshToken(),
        })
        .then(response => {
          const {token} = response.data;
          loginTokenService.updateToken(token);
          return token;
        });
    }
    return Promise.resolve();
  }
}
