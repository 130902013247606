import './image.scss';

const ImageCarouselComponent = {
  templateUrl: '/app/assets/component/carousel/image.html',
  controller: 'CarouselController',
  bindings: {
    items: '<',
    slug: '<',
  },
};

export default ImageCarouselComponent;
