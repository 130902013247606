HeadService.$inject = ['$rootScope', 'DataLayerService'];

export default function HeadService($rootScope, DataLayer) {
  return {setTitle};

  function setTitle(title) {
    $rootScope.title = title;
    DataLayer.logPageView(title);
  }
}
