import './_styles.scss';

import {tsAndCs} from '../../../../environment/constants';
import component from './component.html';

const CheckoutOptionComponent = {
  template: component,
  controller: CheckoutOptionController,
  bindings: {
    close: '&',
    activePanel: '<',
    setOptions: '&',
    product: '<',
    variant: '<',
  },
};

export default CheckoutOptionComponent;

CheckoutOptionController.$inject = [];
export function CheckoutOptionController() {
  const vm = this;

  vm.total = {
    cost: 0,
    tickets: 0,
  };

  vm.errors = {};
  vm.tooManyTickets = false;
  vm.tooManySupplements = false;

  vm.pickOptionsForm = {};

  vm.tickets = {};

  vm.showHurry = false;
  vm.isAnarchy = false;

  vm.tsAndCs = tsAndCs.ticket;

  vm.updateTotal = updateTotal;
  vm.disabled = disabled;

  vm.$onInit = () => {
    if (
      vm.product.hasOwnProperty('terms_and_conditions') &&
      !!vm.product.terms_and_conditions
    ) {
      vm.tsAndCs = vm.product.terms_and_conditions;
    }
    vm.isAnarchy = !!vm.variant.features.anarchy;

    vm.tickets = [...vm.variant.prices, ...vm.variant.supplements].reduce(
      (tickets, price) => {
        tickets[price.slug] = {
          label: price.label,
          amount: null,
        };

        return tickets;
      },
      {}
    );

    vm.isFree = !vm.variant.prices
      .map(price => price.price)
      .reduce((total, price) => total + price, 0);

    vm.showHurry =
      vm.variant.stock_quantity !== null &&
      vm.variant.stock_quantity < vm.variant.tickets_per_partner;
  };

  vm.addOptions = () => {
    vm.setOptions({options: vm.tickets});
  };

  function updateTotal() {
    vm.total.cost = 0;
    vm.total.tickets = 0;

    vm.variant.prices.forEach(price => {
      if (vm.tickets.hasOwnProperty(price.slug)) {
        const numberOfTickets = vm.tickets[price.slug].amount;

        vm.total.cost += price.price * numberOfTickets;
        vm.total.tickets += numberOfTickets;
      }
    });

    vm.variant.supplements.forEach(supplement => {
      if (vm.tickets.hasOwnProperty(supplement.slug)) {
        const numberOfSupplements = vm.tickets[supplement.slug].amount;

        vm.total.cost += supplement.price * numberOfSupplements;
      }
    });

    vm.tooManyTickets =
      vm.total.tickets > vm.variant.tickets_per_partner ||
      vm.total.tickets > vm.variant.stock_quantity;
  }

  function disabled() {
    let disabled = false;

    try {
      formIsValid();

      ticketsHaveBeenOrdered();

      termsAgreedTo();

      enoughTicketsAvailable();

      notTooManyTicketsHaveBeenOrdered();

      // if not in state of anarchy run the extended rules
      if (!vm.isAnarchy) {
        partnerPriceTicketHasBeenOrdered();

        notSetUpErrorsForTickets();

        oneFreeTextPerTicket();
      }
    } catch (e) {
      disabled = true;
    }

    return disabled;

    function formIsValid() {
      if (vm.pickOptionsForm.$invalid) {
        throw 'form is invalid';
      }
    }

    function ticketsHaveBeenOrdered() {
      if (!vm.total.tickets) {
        throw 'no Tickets ordered';
      }
    }

    function notTooManyTicketsHaveBeenOrdered() {
      if (
        vm.variant.tickets_per_partner &&
        vm.total.tickets > vm.variant.tickets_per_partner
      ) {
        throw 'To many tickets have been ordered';
      }
    }

    function enoughTicketsAvailable() {
      if (vm.total.tickets > vm.variant.stock_quantity) {
        throw 'Not enough tickets available';
      }
    }

    function partnerPriceTicketHasBeenOrdered() {
      const partnerPrice = vm.variant.prices[0].slug;

      if (
        !vm.tickets.hasOwnProperty(partnerPrice) ||
        vm.tickets[partnerPrice].amount <= 0
      ) {
        throw 'No Partner Price tickets have been ordered';
      }
    }

    function termsAgreedTo() {
      if (!vm.terms) {
        throw 'Terms and conditions have not been agreed to';
      }
    }

    function notSetUpErrorsForTickets() {
      if (Object.keys(vm.errors).filter(key => vm.errors[key]).length) {
        throw 'Errors exist';
      }
    }

    function oneFreeTextPerTicket() {
      vm.variant.supplements.forEach(supplement => {
        if (
          vm.tickets.hasOwnProperty(supplement.slug) &&
          vm.tickets[supplement.slug].amount > vm.total.tickets
        ) {
          vm.tooManySupplements = true;

          throw 'Can only order 1 free text per ticket';
        }
        vm.tooManySupplements = false;
      });
    }
  }
}
