import './style.scss';

const SearchPanelComponent = {
  bindings: {
    setPanel: '&',
  },
  controller: SearchPanelController,
  templateUrl: '/app/assets/component/search-panel/search-panel.html',
};
export default SearchPanelComponent;

SearchPanelController.$inject = ['$state'];

export function SearchPanelController($state) {
  const vm = this;

  // Variables
  vm.search = '';

  // functions
  vm.doTheSearch = doTheSearch;
  vm.panel = panel => vm.setPanel({panel});

  function doTheSearch() {
    $state.go('Page.Search', {search: vm.search});

    vm.search = '';
  }
}
