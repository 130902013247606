import angular from 'angular';

import environment from '../../../environment';

const {debug, awsUrl} = environment.settings;

NotificationService.$inject = ['$http', 'MessageService'];

export const endpoint = '/notifications';
export default function NotificationService($http, message) {
  const service = {
    loading: false,
    notifications: [],
    set: setNotifications,
    load: loadNotifications,
    delete: deleteNotification,
  };

  return service;

  function setNotifications(notificatons) {
    angular.copy(notificatons, service.notifications);
  }

  function loadNotifications() {
    service.loading = true;
    return $http
      .get(`${awsUrl}${endpoint}`)
      .then(({data}) => setNotifications(data))
      .catch(error => showErrorMessage(error, 'Could not load notifications.'))
      .finally(() => (service.loading = false));
  }

  function deleteNotification({notificationId}) {
    service.loading = true;
    return $http
      .delete(`${awsUrl}${endpoint}/${notificationId}`)
      .then(() => loadNotifications())
      .catch(error => showErrorMessage(error, 'Could not delete notification.'))
      .finally(() => (service.loading = false));
  }

  function showErrorMessage(error, defaultMessage) {
    const errorMessage = !debug
      ? defaultMessage
      : `${defaultMessage}\n\n${JSON.stringify(error, null, 2)}`;

    message.error(errorMessage);
  }
}
