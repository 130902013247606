import angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import AuthInterceptor from './auth-interceptor';
import AuthToken from './auth-token-service';
import loginTokenService from './login-token-service';
import { AuthService } from '../../angular/services/auth.service';

export default angular
  .module('PartnerChoice.auth', [])
  .factory('AuthToken', AuthToken)
  .factory('AuthInterceptor', AuthInterceptor)
  .factory('LoginTokenService', loginTokenService)
  .factory('NewAuthService', downgradeInjectable(AuthService));
