import './product-extra-info.scss';

import modalTemplate from './modal.html';
import template from './product-extra-info.html';

const ProductExtraInfoComponent = {
  template,
  controller: ProductExtraInfoController,
  bindings: {
    type: '@',
    productInfo: '=',
    productId: '=',
  },
};

export default ProductExtraInfoComponent;

ProductExtraInfoController.$inject = ['ModalService', 'ProductRatingService'];
export function ProductExtraInfoController(ModalService, ProductRatingService) {
  const vm = this;

  vm.display = display;
  vm.setAccordion = setAccordion;
  vm.displayAccordion = null;

  function display(item) {
    ProductRatingService.redeem(item.product_id);

    ModalService.showModal({
      template: modalTemplate,
      controller: 'ProductExtraInfoModalController',
      controllerAs: '$ctrl',
      bodyClass: 'modal-open',
      inputs: {
        item,
      },
    });
  }

  function setAccordion(accordionTitle) {
    vm.displayAccordion = vm.displayAccordion !== accordionTitle ? accordionTitle : null;
  }

  vm.removeFileExtension = function(filename) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  };
}
