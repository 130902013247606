// Module
var code = `<div id="modal" class="issue-magazine-modal">
  <div class="modal-content">
    <button type="button" class="close" data-ng-click="\$ctrl.close()">
      <span class="fa fa-fw fa-times"></span>
    </button>
    <div class="body">
      <iframe
        allowfullscreen="true"
        data-ng-src="{{ \$ctrl.iframeUrl }}"
        ng-class="{'budget': \$ctrl.budgetClass, 'iframe': true }"
      ></iframe>
    </div>
  </div>
</div>
`;
// Exports
export default code;