AuthTokenService.$inject = ['$window', 'StorageService', 'NewAuthService'];

export default function AuthTokenService($window, Storage, NewAuthService) {
  const blankToken = {
      id: null,
      capabilities: {},
      userData: {},
      timestamp: null,
      build: null,
      hash: null,
      last_login: null,
      jwt: null,
    },
    token = {...blankToken};

  const service = {
    set,
    get,
    jwt,
    id,
    capabilities,
    userData,
    timestamp,
    build,
    hash,
    last_login,
    token: getToken,
    hasCapability,
  };

  init();

  return service;

  function init() {
    const _token_ = Storage.get('AuthToken');

    !!_token_ && set(_token_);
  }

  function set(authString) {
    let _token_;

    try {
      _token_ = JSON.parse($window.atob(authString));

      token.id = _token_.id;
      token.capabilities = _token_.capabilities;
      token.userData = _token_.userData;
      token.timestamp = _token_.timestamp;
      token.hash = _token_.hash;
      token.last_login = _token_.last_login;
      token.jwt = _token_.jwt;

      if (_token_.build) {
        token.build = _token_.build;
      }

      Storage.set('AuthToken', get());
    } catch (e) {
      angular.copy(blankToken, token);
      Storage.set('AuthToken', null);
      Storage.set('partnerchoice_api_refresh_token', null);
      Storage.set('partnerchoice_api_token', null);
    }
    NewAuthService.tokenChanged();
  }

  function get() {
    return $window.btoa(JSON.stringify(getToken()));
  }

  function jwt() {
    return token.jwt;
  }

  function id() {
    return token.id;
  }

  function capabilities() {
    return {...token.capabilities};
  }

  function userData() {
    const {userData: _userData} = token;

    return userData ? [..._userData] : null;
  }

  function timestamp() {
    return token.timestamp;
  }

  function build() {
    return token.build;
  }

  function hash() {
    return token.hash;
  }

  function last_login() {
    return token.last_login;
  }

  function getToken() {
    return {...token};
  }

  function hasCapability(type, capability) {
    let _hasCapability;

    try {
      _hasCapability = token.capabilities[type].indexOf(capability) !== -1;
    } catch (e) {
      _hasCapability = false;
    }

    return _hasCapability;
  }
}
