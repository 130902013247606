// Module
var code = `<div class="my-ballots my-orders grid-layout">
  <div class="row clearfix max-width">
    <div class="small-12 columns account-row">
      <a data-ui-sref="Page.User" title="">
        <span class="fa fa-fw fa-arrow-left"></span> Back to My Account
      </a>
    </div>
  </div>
  <div class="row clearfix max-width">
    <div class="small-12 columns header-row account-row">
      <h2>My Orders</h2>
      <a class="right key" data-ng-click="\$ctrl.showKey = !\$ctrl.showKey">
        <span class="fa fa-info-circle"></span>
        What does this mean?
        <span
          class="fa"
          data-ng-class="{'fa-caret-down' : !\$ctrl.showKey, 'fa-caret-up' : \$ctrl.showKey}"
        ></span>
      </a>
    </div>
  </div>
  <div class="information-panel" data-ng-if="\$ctrl.showKey">
    <div class="small-12 columns header-row">
      <dl class="dl-horizontal">
        <dt>Awaiting ballot</dt>
        <dd>The ballot has not yet drawn winners</dd>
        <dt>Pending</dt>
        <dd>
          You have not currently won tickets, however if any tickets are
          re-balloted, you may win at a later date.
        </dd>
        <dt>Won</dt>
        <dd>You have won tickets and need to pay for them within 5 days.</dd>
        <dt>Expired</dt>
        <dd>
          Your tickets have been re-balloted to other Partners, as you failed to
          pay within the time limit.
        </dd>
        <dt>Purchased</dt>
        <dd>You have bought your tickets.</dd>
        <dt>Ended</dt>
        <dd>The ballot has ended, you have not won tickets.</dd>
      </dl>
    </div>
  </div>
  <div class="row clearfix max-width">
    <div class="small-12 medium-10 columns">
      <div>
        <span
          class="filter-label"
          data-ng-class="{'active' : \$ctrl.activeTab == tab}"
          data-ng-repeat="tab in \$ctrl.tabs"
          data-ng-click="\$ctrl.setTab(tab)"
        >
          {{ tab }}
        </span>
      </div>
    </div>
  </div>

  <div
    class="item"
    data-ng-repeat-start="entry in \$ctrl.myOrders | filter : \$ctrl.filter | orderBy : 'created' : true"
    data-ng-class="entry.state"
  >
    <ballot-summary-component
      data-ng-if="entry.Product.ballot"
      data-ballot="entry"
      data-buy="\$ctrl.purchase"
      data-withdraw="\$ctrl.withdraw"
      data-edit="\$ctrl.editBallotEntry"
    >
    </ballot-summary-component>
    <ticket-summary-component
      data-ng-if="entry.Product.ticket"
      data-ticket="entry"
    ></ticket-summary-component>
    <jlp-product-summary-component
      data-ng-if="entry.Product.product"
      data-product="entry"
    ></jlp-product-summary-component>
    <odney-summary-component
      data-ng-if="entry.Product.odney"
      data-odney="entry"
    ></odney-summary-component>
  </div>

  <div
    data-ng-repeat-end
    data-ng-if="\$odd || (\$index+1)%3==0 || (\$index+1)%4==0"
    data-ng-class="{'mobile-break' : \$odd, 'tablet-break' : (\$index+1)%3==0, 'desktop-break' : (\$index+1)%4==0 }"
  ></div>
</div>
`;
// Exports
export default code;