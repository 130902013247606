import $ from 'jquery';
/**
 * Register the event handlers
 */
$(() => {
  const $window = $(window);

  $window.on('scroll', window.layout.scroll);
  // $window.on('resize', window.layout.resize);
});
