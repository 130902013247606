export default function filterObjectFilter() {
  return function (objects, filteringObject) {
    // set up the list
    const filtered = [];

    angular.forEach(objects, item => {
      angular.forEach(filteringObject, (value, field) => {
        if (item[field] !== 'undefined' && isInString(value, item[field])) {
          filtered.push(item);
        }
      });
    });

    // return the list
    return filtered;
  };

  function isInString(needle, haystack) {
    needle = needle.toLowerCase();
    haystack = haystack.toLowerCase();

    return haystack.indexOf(needle) !== -1;
  }
}
