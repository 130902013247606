// Module
var code = `<section class="pins">
  <div
    data-ng-repeat="info in \$ctrl.productInfo | filter : {type : \$ctrl.type}| orderBy : 'order'"
    data-ng-click="\$ctrl.display(info)"
    data-ng-if="info.type == 'payment'"

  >
    <span class="fa fa-info-circle"></span> {{ info.title }}
  </div>
</section>

<section class="accordions">
  <section
    data-ng-repeat="info in \$ctrl.productInfo | filter : {type : \$ctrl.type}| orderBy : 'order'"
    class="accordion extra-info-accordion"
    data-ng-if="info.type == 'product'"
  >
    <header
      data-ng-click="\$ctrl.setAccordion(info.title)"
      class="dropdown button secondary tiny"
    >
      <h1>{{info.title }}</h1>
    </header>

    <section
      data-ng-show="\$ctrl.displayAccordion == info.title"
      class="expanded"
    >
      <div class="body">
        <div>
          <div data-ng-bind-html="info.description"></div>
          <div
            data-ng-if="info.file_type.indexOf('image') > -1 && !info.thumbnails"
          >
            <img
              alt="{{ info.file_title }}"
              data-ng-src="{{ '/product-extra-info-file/' + info.id + '/' + info.file_slug  }}"
            />
          </div>
          <div
            data-ng-if="info.file_type.indexOf('image') == -1 && info.file_title"
            class="download"
          >
            <a
              data-ng-href="/product-extra-info-file/{{ info.id }}/{{ info.file_slug }}"
              target="_self"
            >
              {{  \$ctrl.removeFileExtension(info.file_title)}}
              <span></span>
            </a>
          </div>

          <jlp-image-carousel
            data-ng-if="info.thumbnails"
            data-slug="info.id"
            class="modal-carousel"
            data-items="info.thumbnails"
          ></jlp-image-carousel>
        </div>
      </div>
    </section>
  </section>
</section>
`;
// Exports
export default code;