import '@uirouter/angularjs';
import 'angular-sanitize';

import angular from 'angular';

import config from './config';
import ControllerModule from './controller/controller-module';
import ProviderModule from './provider/provider-module';
import ServiceModule from './services/service-module';

// do we need the core directive in here..
export default angular
  .module('PartnerChoice.guest', [
    'ui.router',
    'ngSanitize',

    ControllerModule.name,
    ProviderModule.name,
    ServiceModule.name,
  ])
  .config(config);
