const MessageComponent = {
  template: `<div class="messages" data-ng-show="$ctrl.message.running">
  <section>
  <section data-ng-class="$ctrl.message.current.status">
  <span data-ng-click="$ctrl.cancel()"><span></span><span></span></span>
  {{ $ctrl.message.current.message }}
  </section>
  </section>
  <div class="layer-overlay"></div>
  </div>`,
  controller: MessageController,
};

export default MessageComponent;

MessageController.$inject = ['MessageService'];
export function MessageController(messageService) {
  const vm = this;

  vm.message = messageService.message;

  vm.cancel = messageService.cancel;
}
