import angular from 'angular';

import globalStyleService from './global-style-service';
import messageService from './message-service';
import productRatingService from './product-rating-service';
import productService from './product-service';
import userService from './user-service';

export default angular
  .module('PartnerChoice.user.service', [])
  .factory('GlobalStyle', globalStyleService)
  .factory('MessageService', messageService)
  .factory('ProductRatingService', productRatingService)
  .factory('ProductService', productService)
  .factory('UserService', userService)
  .factory('zE', () => window.zE || {});
