/**
 * Tool tip directive that relies on foundation styling
 *
 * Can be
 */
toolTipDirective.$inject = ['$compile', '$document'];

const template = `
	<span data-selector="tooltip-i2ylpzwm5" id="tooltip-i2ylpzwm5" class="tooltip" role="tooltip" style="visibility: visible; width: auto; " data-ng-bind-html="tip">
			<span class="nub"></span>
	</span>`;

export default function toolTipDirective($compile, $document) {
  return {
    scope: {
      tip: '@',
    },
    link: link,
  };

  function link(scope, element) {
    const linkFn = $compile(template),
      $toolTip = linkFn(scope),
      $body = $document.find('body').eq(0);

    element.bind('mouseenter', () => {
      const position = element.offset(),
        height = element[0].scrollHeight;

      $toolTip
        .css('display', 'block')
        .css('z-index', 999999)
        .css('left', position.left)
        .css('top', position.top + height);

      $body.append($toolTip);

      // add to the DOM
    });

    element.bind('mouseleave tap', () => {
      $toolTip.css('display', 'none');
    });
  }
}
