const SearchResultComponent = {
  templateUrl: '/app/assets/component/search-result/component.html',
  controller: SearchResultController,
};

export default SearchResultComponent;

SearchResultController.$inject = [
  '$stateParams',
  'ProductService',
  'cfpLoadingBar',
  'DataLayerService',
];

export function SearchResultController(
  $stateParams,
  ProductService,
  cfpLoadingBar,
  dataLayer
) {
  let productsOnPage = () => {};

  const vm = this;

  vm.items = ProductService.items;
  vm.limit = ProductService.limit;
  vm.search = $stateParams.search;
  vm.registerProductsOnPage = register;
  vm.$onInit = init;

  vm.nextPage = ProductService.nextPage;

  vm.loaded = function () {
    const progress = cfpLoadingBar.status();

    return progress === 0 || progress === 1;
  };

  function init() {
    ProductService.filter($stateParams.search).then(() => {
      dataLayer.logSearch($stateParams.search);
      return productsOnPage();
    });
  }

  function register(handler) {
    productsOnPage = handler;
  }
}
