// Module
var code = `<div
  class="panel"
  data-ng-class="{'active-panel' : \$ctrl.activePanel == 'options'}"
>
  <div class="panel-heading">
    <h4 class="panel-title">
      Ticket Options
      <span
        class="fa fa-check-square-o"
        data-ng-class="{'hide' : !checkout.status.billing()}"
      ></span>
    </h4>
  </div>
  <div class="panel-body">
    <section
      data-ng-form="\$ctrl.pickOptionsForm"
      data-ng-class="{'panel callout' : \$ctrl.tooManyTickets }"
    >
      <section class="prices" data-ng-repeat="price in \$ctrl.variant.prices">
        <div class="row">
          <div class="small-12 columns">
            <div
              class="row"
              data-ng-class="{'error' : \$ctrl.errors[price.slug] || \$ctrl.tooManyTickets }"
            >
              <div class="small-4 columns">
                <label class="right inline">
                  {{ price.label }} at {{ !!price.price && (price.price/100 |
                  currency : '&pound;' : 2) || 'Free' }}
                </label>
              </div>
              <div class="small-8 columns">
                <input
                  type="number"
                  ng-pattern="/^\\d+\$/"
                  data-ng-model="\$ctrl.tickets[price.slug].amount"
                  min="0"
                  data-ng-change="\$ctrl.updateTotal()"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <section
      class="supplements"
      data-ng-repeat="supplement in \$ctrl.variant.supplements"
    >
      <div class="row">
        <div class="small-12 columns">
          <div
            class="row"
            data-ng-class="{'error' : \$ctrl.errors[supplement.slug] || \$ctrl.tooManySupplements}"
          >
            <div class="small-4 columns">
              <label class="right inline">
                {{ supplement.label }} at {{ !!supplement.price &&
                (supplement.price/100 | currency : '&pound;' : 2) || 'Free' }}
              </label>
            </div>
            <div class="small-8 columns">
              <input
                type="number"
                ng-pattern="/^\\d+\$/"
                data-ng-model="\$ctrl.tickets[supplement.slug].amount"
                min="0"
                data-ng-change="\$ctrl.updateTotal()"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <p
      data-ng-class="{'error' : \$ctrl.total.tickets > \$ctrl.variant.tickets_per_partner, 'info' : \$ctrl.total.tickets <= \$ctrl.variant.tickets_per_partner}"
    >
      You are only allowed {{ \$ctrl.variant.tickets_per_partner }} ticket{{
      \$ctrl.variant.tickets_per_partner > 1 && 's' || '' }} per Partner
    </p>

    <p class="panel callout warning" data-ng-show="\$ctrl.showHurry">
      There {{ \$ctrl.variant.stock_quantity == 1 && 'is' || 'are' }} only {{
      \$ctrl.variant.stock_quantity }} left to purchase, be quick before they're
      all gone!
    </p>

    <jlp-terms-component
      terms-url="\$ctrl.tsAndCs"
      on-agree="\$ctrl.terms = true"
      on-disagree="\$ctrl.terms = false"
      close="\$ctrl.close()"
    ></jlp-terms-component>

    <div class="full-width">
      <section class="price">
        {{ !\$ctrl.isFree && (\$ctrl.total.cost/100 | currency : '&pound;') ||
        'Free' }}
      </section>
      <button
        class="button accordion-toggle"
        data-ng-click="\$ctrl.addOptions()"
        data-ng-disabled="\$ctrl.disabled()"
      >
        Add Tickets
      </button>
    </div>
  </div>
</div>
`;
// Exports
export default code;