import './notification-modal.scss';

NotificationModal.$inject = ['close', 'NotificationService'];

export default function NotificationModal(close, service) {
  const vm = this;

  vm.close = close;
  vm.notifications = service.notifications;
  vm.delete = service.delete;
}
