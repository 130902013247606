import angular from 'angular';

import environment from '../../../environment';
import FeatureButtonComponent from './button/feature-button-component';
import FeatureActiveDirective from './display/feature-active-directive';
import FeatureInactiveDirective from './display/feature-inactive-directive';
import FeatureService from './feature-service';
import FeatureModalController from './modal/feature-modal-controller';

const FeatureModule = angular
  .module('PartnerChoice.features', [])
  .directive('jlpFeatureActive', FeatureActiveDirective)
  .directive('jlpFeatureInactive', FeatureInactiveDirective)
  .factory('FeatureService', FeatureService);

if (environment.settings.debug) {
  FeatureModule.component(
    'jlpFeatureButton',
    FeatureButtonComponent
  ).controller('FeaturesModal', FeatureModalController);
}

export default FeatureModule;
