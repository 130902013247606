const ProductPriceComponent = {
  templateUrl: '/app/assets/component/product-price/component.html',
  controller: ProductPriceController,
  bindings: {
    product: '<',
  },
};

export default ProductPriceComponent;

export function ProductPriceController() {
  const vm = this;

  vm.$onChanges = onChanges;

  function onChanges() {
    setPrice();
  }

  function setPrice() {
    const isPurchasable = vm.product.features.purchasable;

    vm.price = isPurchasable ? price() : text();
  }

  function price() {
    return {
      title: vm.product.from_price ? 'from' : null,
      price:
        vm.product.from_price > 0
          ? `£${(vm.product.from_price / 100).toFixed(2)}`
          : 'free',
    };
  }

  function text() {
    const {price_label = '', price_label_value = ''} = vm.product;

    return {
      price: `${price_label || ''} ${price_label_value || ''}`.trim() || null,
    };
  }
}
