import NotificationComponent from './notification-component';
import NotificationModal from './notification-modal';
import NotificationService from './notification-service';

const NotificationModule = angular
  .module('PartnerChoice.notifications', [])
  .factory('NotificationService', NotificationService)
  .component('jlpNotifications', NotificationComponent)
  .controller('NotificationModal', NotificationModal);

export default NotificationModule;
