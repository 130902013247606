StorageService.$inject = ['MessageService'];

export default function StorageService(MessageService) {
  return {set, get};

  function set(prop, value) {
    try {
      localStorage[prop] = JSON.stringify(value);
    } catch (e) {
      noLocalStorage();
    }
  }

  function get(prop) {
    if (typeof localStorage !== 'undefined' && localStorage[prop]) {
      return JSON.parse(localStorage[prop]);
    }

    return null;
  }

  function noLocalStorage() {
    MessageService.error(
      'Your browser does not support partnerchoice, please update your browser or exit private mode, to be able to see the latest offers available'
    );
  }
}
