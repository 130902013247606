import './_styles.scss';

const CheckoutAddressComponent = {
  templateUrl: '/app/assets/component/checkout/address/component.html',
  controller: CheckoutAddressController,
  bindings: {
    activePanel: '<',
    deliverable: '<',
    setAddress: '&',
    free: '<',
    title: '<',
  },
};

export default CheckoutAddressComponent;

CheckoutAddressController.$inject = ['UserService', 'ModalService'];
export function CheckoutAddressController(User, ModalService) {
  const vm = this;

  vm.user = User.user;
  vm.addressIndex = null;

  vm.address = address;
  vm.editAddressModal = editAddressModal;
  vm.hasPhone = hasPhone;
  vm.status = status;

  vm.$onChanges = () => {
    vm.shouldDisplay =
      (!vm.free && vm.title === 'billing') ||
      (vm.deliverable && vm.title === 'shipping');
  };

  function address() {
    const address = {...vm.user.UserAddress[vm.addressIndex].address};

    vm.setAddress({address});
  }

  function editAddressModal(index) {
    // If we have specified an address
    const isExisting = vm.user.UserAddress.hasOwnProperty(index)
        ? index
        : false,
      address = isExisting ? vm.user.UserAddress[index] : {address: {}};

    ModalService.showModal({
      templateUrl: 'app/view/modals/edit-address.html',
      controller: 'editAddress',
      controllerAs: 'modal',
      bodyClass: 'modal-open',
      inputs: {address: address},
    }).then(modal => {
      modal.close.then(updatedAddress => {
        if (updatedAddress) {
          const id = updatedAddress.hasOwnProperty('id')
            ? updatedAddress.id
            : null;

          User.editAddress(updatedAddress, id);
        }

        // do saving stuff
      });
    });
  }

  function hasPhone() {
    return (
      vm.addressIndex && !vm.user.UserAddress[vm.addressIndex].address.phone
    );
  }

  function status() {
    return (
      vm.addressIndex &&
      vm.user.UserAddress.hasOwnProperty(vm.addressIndex) &&
      vm.user.UserAddress[vm.addressIndex].address.phone
    );
  }
}
