import 'angular-sanitize';
import 'angular-animate';
import 'angular-modal-service';
import 'ng-infinite-scroll';
import 'angular-hammer';

import angular from 'angular';

import ComponentModule from '../../component/component-module';
import config from './config';
import ControllerModule from './controller/controller-module.js';
import DirectiveModule from './directive/directive-module';
import ProviderModule from './provider/provider-module';
import ServiceModule from './service/service-module';

export default angular
  .module('PartnerChoice.user', [
    'ngSanitize',
    'ngAnimate',
    'angularModalService',
    'infinite-scroll',
    'hmTouchEvents',

    ComponentModule.name,

    ControllerModule.name,
    DirectiveModule.name,
    ProviderModule.name,
    ServiceModule.name,
  ])
  .config(config);
