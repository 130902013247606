import './notification-component.scss';

// import NotificationModalController from './notification-modal';
// import template from './notification-modal.html';

const NotificationComponent = {
  templateUrl: '/app/assets/component/notification/notification-component.html',
  controller: NotificationController,
  replace: true,
};
export default NotificationComponent;

NotificationComponent.$inject = [
  'NotificationService',
  'ModalService',
  'UserService',
];

// export function NotificationController(service, modals, user) {
export function NotificationController() {
  // const vm = this;
  // vm.user = user.user;
  // vm.notifications = service.notifications;
  // vm.toggleNotifications = toggleNotifications;
  // vm.isVisible = false;
  // service.load();
  // function toggleNotifications() {
  //   modals.closeModals();
  //   !vm.isVisible &&
  //     modals
  //       .showModal({
  //         template,
  //         controller: NotificationModalController,
  //         controllerAs: 'modal',
  //         bodyClass: 'modal-open',
  //       })
  //       .then(modal => {
  //         vm.isVisible = true;
  //         modal.close.then(() => (vm.isVisible = false));
  //       });
  // }
}
