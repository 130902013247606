import {welcomePageUrl} from '../../../../environment/constants';

loginController.$inject = [
  'Url',
  '$http',
  'LeisureJsonApi',
  '$window',
  '$stateParams',
  'AuthToken',
  'DataLayerService',
  'LoginService',
];

export default function loginController(
  Url,
  $http,
  JsonApi,
  $window,
  $stateParams,
  AuthToken,
  DataLayer,
  LoginService
) {
  /** ============================================ *
   * View Model
   * ============================================= */
  const vm = this;

  /** ============================================ *
   * View Variables
   * ============================================= */
  vm.registered = !!$stateParams.email;
  vm.reset = !!$stateParams.reset;

  vm.details = {
    name: $stateParams.name,
    email: $stateParams.email,
  };

  vm.user = {
    email: $stateParams.email,
  };

  vm.message = '';

  /** ============================================ *
   * View Functions
   * ============================================= */
  vm.login = logMeIn;

  AuthToken.set();

  /** ============================================ *
   * Protected/private functions
   * ============================================= */
  function logMeIn() {
    vm.message = '';

    Promise.allSettled([
      $http.post(JsonApi.buildUrl('Login'), {User: vm.user}),
      LoginService.login(vm.user.email, vm.user.password),
    ]).then(result => {
      let response;
      if (!result[0].value?.data?.status || result[1].status === 'rejected') {
        AuthToken.set();
        LoginService.logout();
        if (!result[0].value?.data?.status) {
          response = result[0].value;
        } else {
          response = {
            data: {
              status: false,
              message: result[1].reason?.data?.error ?? 'Login failed',
            },
          };
        }
      } else {
        response = result[0].value;
      }
      JsonApi.handleResponse(response, pass);
    });
  }

  function pass() {
    DataLayer.logLogin();
    $window.location.href = path();
  }

  function path() {
    const welcomePage = !AuthToken.last_login();
    const redirectUrl = localStorage.getItem('loginRedirect');
    localStorage.removeItem('loginRedirect');
    const newRedirect = (redirectUrl?.includes('Forgotten/reset/') || redirectUrl?.includes('Login')) ? '/' : redirectUrl;
    return welcomePage ? welcomePageUrl : newRedirect || '/';
  }
}
