import {NG_HIDE_CLASS, NG_HIDE_IN_PROGRESS_CLASS} from './show-hide-contant';

FeatureActiveDirective.$inject = ['$animate', 'FeatureService'];

function FeatureActiveDirective($animate, featureService) {
  return {
    restrict: 'A',
    multiElement: true,
    scope: {feature: '@jlpFeatureActive'},
    link,
  };
  function link(scope, element) {
    featureService.features.subscribe(features => {
      const value =
        features[scope.feature] && features[scope.feature].active
          ? 'removeClass'
          : 'addClass';

      $animate[value](element, NG_HIDE_CLASS, {
        tempClasses: NG_HIDE_IN_PROGRESS_CLASS,
      });
    });
  }
}

export default FeatureActiveDirective;
