RoutesProvider.$inject = [];

export default function RoutesProvider() {
  this.$get = function () {
    return {
      Guest: {
        abstract: true,
        views: {
          page: {
            templateUrl: 'app/view/pages/page.html',
            controller: 'GuestPageController',
            controllerAs: 'page',
          },
          messages: 'messages',
          'navigationButtons@Guest': {
            templateUrl: 'app/view/guest/navigation-buttons.html',
          },
        },
      },
      'Guest.login': {
        title: 'Login',
        url: '/Login',
        views: {
          main: {
            templateUrl: 'app/view/guest/login.html',
            controller: 'LoginController',
            controllerAs: 'login',
          },
        },
        params: {
          email: {
            value: null,
            squash: true,
          },
          name: {
            value: null,
            squash: true,
          },
          reset: {
            value: null,
            squash: true,
          },
        },
      },
      'Guest.logout': {
        title: 'Logout',
        url: '/Logout',
        views: {
          main: {
            controller: 'LogoutController',
            controllerAs: 'logout',
          },
        },
      },
      'Guest.register': {
        title: 'Register',
        url: '/register',
        views: {
          main: {
            templateUrl: 'app/view/guest/register.html',
            controller: 'RegisterController',
            controllerAs: 'register',
          },
        },
      },
      'Guest.forgotten': {
        title: 'Forgotten Password',
        url: '/forgotten',
        views: {
          main: {
            templateUrl: 'app/view/guest/forgotten.html',
            controller: 'ForgottenController',
            controllerAs: 'forgot',
          },
        },
      },
      'Guest.reset': {
        title: 'Reset Password',
        url: '/Forgotten/reset/{userHash}',
        views: {
          main: {
            templateUrl: 'app/view/guest/reset.html',
            controller: 'ResetController',
            controllerAs: 'reset',
          },
        },
      },
    };
  };
}
