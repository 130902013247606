import angular from 'angular';

import BallotSummaryCommponent from './ballot/ballot-summary-component';
import OrdersListComponent from './list/orders-list-component';
import OdneySummaryComponent from './odney/odney-summary-component';
import ProductSummaryComponent from './product/product-summary-component';
import TicketSummaryComponent from './ticket/ticket-summary-component';

export default angular
  .module('PartnerChoice.user.orders', [])
  .component('ordersListComponent', OrdersListComponent)
  .component('ballotSummaryComponent', BallotSummaryCommponent)
  .component('odneySummaryComponent', OdneySummaryComponent)
  .component('jlpProductSummaryComponent', ProductSummaryComponent)
  .component('ticketSummaryComponent', TicketSummaryComponent);
