import environment from '../../../environment';

const {awsUrl, leisureUrl} = environment.settings;

LeisureJsonApiService.$inject = ['JsonApi'];

export default function LeisureJsonApiService(JsonApi) {
  return {
    buildUrl,
    buildAwsUrl,
    handleResponse: JsonApi.handleResponse,
  };

  function buildUrl(url, params) {
    return JsonApi.buildUrl(`${leisureUrl}/json/${url}`, params);
  }

  function buildAwsUrl(url, params) {
    return JsonApi.buildUrl(`${awsUrl}/${url}`, params);
  }
}
