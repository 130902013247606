import '@uirouter/angularjs';
import 'angular-loading-bar';

import angular from 'angular';

import {config, run} from './config';
import ControllerModule from './controller/controller-module';
import DirectiveModule from './directive/directive-module';
import FilterModule from './filter/filter-module';
import InterceptorModule from './interceptor/interceptor-module';
import ServiceModule from './service/service-module';
import ValueModule from './value/value-module';

export default angular
  .module('PartnerChoice.common', [
    'ui.router',
    'angular-loading-bar',

    ControllerModule.name,
    DirectiveModule.name,
    FilterModule.name,
    InterceptorModule.name,
    ServiceModule.name,
    ValueModule.name,
  ])
  .run(run)
  .config(config);
