DataLayerService.$inject = ['$rootScope', '$location'];

export default function DataLayerService($rootScope, $location) {
  const {dataLayer = []} = window;

  return {
    push,
    logPageView,
    logSearch,
    logPurchase,
    logHotelPurchase,
    logLogin,
  };

  function push(stuff) {
    try {
      return dataLayer.push(stuff);
    } catch (error) {
      console.error(error);
    }
  }

  function logPageView(title) {
    try {
      logEvent('page_view', {
        virtualPageTitle: title,
        virtualPageUrl: $location.path(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  function logPurchase({orderId, item}) {
    try {
      const {product_id, product_variant_id, title, total = 0, options = {}} =
        item || {};
      const items = Object.keys(options)
        .filter(slug => options[slug].price)
        .map(slug => {
          const {label, price = 0, qty: quantity} = options[slug];

          return {
            item_id: `${product_id}-${product_variant_id}`,
            item_name: `${title}: ${label}`,
            quantity,
            price: price ? (price / 100).toFixed(2) : 0,
          };
        });
      const data = {
        transaction_id: orderId,
        items,
        currency: 'GBP',
        value: total ? (total / 100).toFixed(2) : 0,
      };

      logEvent('purchase', data);
    } catch (error) {
      console.error(error);
    }
  }

  function logHotelPurchase(booking) {
    try {
      const data = {
        transaction_id: booking.booking.id,
        affiliation: booking.Location.slug,
        items: booking.AccommodationType.map(item => {
          return {
            item_id: item.id,
            item_name: item.code,
            affiliation: booking.Location.slug,
            item_variant: item.Configuration.label,
            quantity: 1,
          };
        }),
        currency: 'GBP',
        value: booking.price.option.toFixed(2),
      };

      logEvent('purchase', data);
    } catch (error) {
      console.error(error);
    }
  }

  function logLogin() {
    try {
      logEvent('login', {loginMethod: 'email'});
    } catch (error) {
      console.error(error);
    }
  }

  function logSearch(searchTerm) {
    try {
      logEvent('search', {searchTerm});
    } catch (error) {
      console.error(error);
    }
  }

  function logEvent(event, data) {
    try {
      push({event, ...data});
    } catch (error) {
      console.error(error);
    }
  }
}
