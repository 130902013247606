import './static-carousel.scss';

const StaticCarouselComponent = {
  templateUrl: '/app/assets/component/carousel/static-carousel.html',
  bindings: {
    items: '<',
  },
};

export default StaticCarouselComponent;
