import environment from '../../environment';

const {
  awsUrl,
  leisureUrl,
  bookingUrl,
  loginAwsUrl,
  newAwsUrls,
  unregisteredAwsUrl
} = environment.settings;

AuthInterceptor.$inject = ['$q', 'AuthToken', 'LoginTokenService', 'NewAuthService'];

export default function AuthInterceptor($q, AuthToken, loginTokenService) {
  const service = {
    request,
    response,
    responseError,
  };

  return service;

  function request(config) {
    const isLoginAwsUrl = config.url.includes(loginAwsUrl);
    const isUnregisteredAwsUrl = config.url.includes(unregisteredAwsUrl);

    if (AuthToken.id()) {
      const isAwsUrl = config.url.includes(awsUrl);
      const isZdUrl = config.url.includes('zendesk.com');
      const isNewAwsUrl = newAwsUrls.some(u => config.url.includes(u));

      !isZdUrl &&
        !isLoginAwsUrl &&
        !isUnregisteredAwsUrl &&
        !isNewAwsUrl &&
        (config.headers.Authorization = !isAwsUrl
          ? AuthToken.get()
          : AuthToken.jwt());
      isNewAwsUrl &&
        (config.headers.Authorization = loginTokenService.getToken());
      if (isAwsUrl || isZdUrl || isLoginAwsUrl || isNewAwsUrl) {
        config.withCredentials = false;
      }
    } else if (isLoginAwsUrl || isUnregisteredAwsUrl) {
      config.withCredentials = false;
    }

    return config;
  }

  function response(_response) {
    setAuthToken(_response);

    return _response;
  }

  function responseError(_response) {
    const {status, config} = _response || {};
    const {url} = config || {};
    const isLeisureUrl = url && url.includes(leisureUrl);
    const isBookingUrl = url && url.includes(bookingUrl);
    const isLoginAwsUrl = config.url.includes(loginAwsUrl);
    const isNewAwsUrl = newAwsUrls.some(u => url && url.includes(u));

    const defer = $q.defer();
    switch (status) {
      case 418:
      case 449:
        setAuthToken(_response);
        defer.reject(_response);
        break;
      case 401:
        if(!window.location.href.includes('Login')) {
          localStorage.setItem('loginRedirect', window.location.href);
        }
        AuthToken.set();
        loginTokenService.removeToken();
        window.location.href = '/Login';
        break
      case 403:
        if (isLeisureUrl || isNewAwsUrl || isLoginAwsUrl) {
          AuthToken.set();
          loginTokenService.removeToken();
          if(!window.location.href.includes('Login')) {
            localStorage.setItem('loginRedirect', window.location.href);
          }
          window.location.href = '/Login';
        }
        if (isBookingUrl) {
          defer.resolve(_response);
        } else {
          defer.reject(_response);
        }
        break;
      default:
        defer.reject(_response);
    }

    return defer.promise;
  }

  function setAuthToken(_response) {
    if (_response.headers('Authorization')) {
      AuthToken.set(_response.headers('Authorization'));
    }
  }
}
