import angular from 'angular';

import deleteController from './delete-controller';
import editAddressController from './edit-address-controller';
import editUserCotroller from './edit-user-controller';
import mainMenuController from './main-menu-controller';
import productDateController from './product-date-controller';
import productLocationController from './product-location-controller';
import productPickOptionsController from './product-pick-options-controller';
// import userClubsController from './user-clubs-controller';
import userPageController from './user-page-controller';

export default angular
  .module('PartnerChoice.user.controller', [])
  .controller('DeleteController', deleteController)
  .controller('editAddress', editAddressController)
  .controller('EditUserController', editUserCotroller)
  .controller('MainMenuController', mainMenuController)
  .controller('ProductDate', productDateController)
  .controller('ProductLocation', productLocationController)
  .controller('ProductPickOptionsController', productPickOptionsController)
  // .controller('UserClubsController', userClubsController)
  .controller('UserPageController', userPageController);
