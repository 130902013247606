import countries from '../../../common/constant/countries';

editAddressController.$inject = ['close', 'address'];

export default function editAddressController(close, address) {
  const vm = this;

  vm.address = address;
  vm.countries = countries;
  vm.showOptions = false;

  const type = address.id ? 'Edit' : 'Add';
  vm.title = type + ' address details';

  vm.footer = {
    confirm: 'Add',
    disabled: disableFooter,
  };

  vm.form = {
    editAddress: {},
  };

  vm.confirm = submit;
  vm.close = close;
  vm.toggle = toggle;
  vm.setCountry = setCountry;
  vm.iconStyle = iconStyle;

  function toggle() {
    vm.showOptions = !vm.showOptions;
  }

  function iconStyle() {
    if (vm.showOptions) {
      return 'fa-sort-up';
    }

    return 'fa-sort-down';
  }

  function setCountry(countryCode) {
    updateCountryCode(countryCode);
    toggle();
  }

  function updateCountryCode(countryCode) {
    vm.address.address.country = countryCode;
  }

  function submit() {
    close(vm.address, 200);
  }

  function disableFooter() {
    return vm.form.editAddress.$invalid;
  }

  function setUp() {
    if (
      !angular.isObject(address.address) ||
      Object.keys(address.address).length > 0
    ) {
      vm.footer.confirm = 'edit';
    } else {
      updateCountryCode('GB');
    }
  }

  setUp();
}
