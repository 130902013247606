import './component.scss';

import countries from '../../common/constant/countries';

const ProfileAddressComponent = {
  templateUrl: '/app/assets/component/profile-address/component.html',
  controller: ProfileAddressController,
};

export default ProfileAddressComponent;

ProfileAddressController.$inject = ['UserService', 'ModalService'];
export function ProfileAddressController(user, ModalService) {
  const vm = this;

  vm.user = user.user;
  vm.countries = countries;

  vm.editAddress = editAddressModal;
  vm.removeAddress = removeAddress;

  function editAddressModal(index) {
    const existing = vm.user.UserAddress.hasOwnProperty(index);
    // If we have specified an address
    const address = existing ? {...vm.user.UserAddress[index]} : {address: {}};

    const modalConfig = {
      templateUrl: 'app/view/modals/edit-address.html',
      controller: 'editAddress',
      controllerAs: 'modal',
      bodyClass: 'modal-open',
      inputs: {address},
    };
    ModalService.showModal(modalConfig).then(modal =>
      modal.close.then(
        updatedAddress => !!updatedAddress && editAddress(updatedAddress)
      )
    );
  }

  function editAddress(updatedAddress) {
    const {id} = {id: false, ...updatedAddress};

    user.editAddress(updatedAddress, id);
  }
  /**
   * Display a confirmation deletion box
   *
   * @param id
   */
  function removeAddress(id) {
    const modalConfig = {
      templateUrl: 'app/view/modals/delete-address.html',
      controller: 'DeleteController',
      controllerAs: 'modal',
      bodyClass: 'modal-open',
      inputs: {
        type: 'delete',
      },
    };

    ModalService.showModal(modalConfig).then(modal =>
      modal.close.then(
        deleteAddress => !!deleteAddress && user.deleteAddress(id)
      )
    );
  }
}
