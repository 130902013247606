import {NG_HIDE_CLASS, NG_HIDE_IN_PROGRESS_CLASS} from './show-hide-contant';

FeatureInactiveDirective.$inject = ['$animate', 'FeatureService'];

function FeatureInactiveDirective($animate, featureService) {
  return {
    restrict: 'A',
    multiElement: true,
    scope: {feature: '@jlpFeatureInactive'},
    link,
  };
  function link(scope, element) {
    featureService.features.subscribe(features => {
      const value =
        features[scope.feature] && features[scope.feature].active
          ? 'addClass'
          : 'removeClass';

      $animate[value](element, NG_HIDE_CLASS, {
        tempClasses: NG_HIDE_IN_PROGRESS_CLASS,
      });
    });
  }
}

export default FeatureInactiveDirective;
