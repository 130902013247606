config.$inject = ['$stateProvider', 'RoutesProvider'];

export default function config($stateProvider, RoutesProvider) {
  // Get the routes from the routes provider
  const routes = RoutesProvider.$get();

  // Now set up the states
  $stateProvider
    .state('Guest', routes['Guest'])
    .state('Guest.Login', routes['Guest.login'])
    .state('Guest.Logout', routes['Guest.logout'])
    .state('Guest.Register', routes['Guest.register'])
    .state('Guest.Forgotten', routes['Guest.forgotten'])
    .state('Guest.Reset', routes['Guest.reset']);
}
