import modalTemplate from './iframe-overlay-modal.html';

const template =
  '<button data-ng-click="$ctrl.showTheIssue()">{{ $ctrl.iframe.title }}</button>';

const IframeButtonComponent = {
  template,
  controller: IframeButton,
  bindings: {
    iframe: '=',
  },
};

export default IframeButtonComponent;

IframeButton.$inject = ['ModalService', 'ProductRatingService'];

function IframeButton(ModalService, ProductRatingService) {
  const vm = this;

  vm.showTheIssue = () => {
    const {
      productId,
      iframe: {url: iframeUrl},
    } = vm;

    ProductRatingService.redeem(productId);

    ModalService.showModal({
      template: modalTemplate,
      controller: 'IframeOverlayModalController',
      controllerAs: '$ctrl',
      bodyClass: 'iframe-modal',
      inputs: {
        iframeUrl,
      },
    });
  };
}
