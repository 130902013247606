import environment from '../../../environment';
import moment from 'moment';

const ProductTicketComponent = {
  templateUrl: '/app/assets/component/product-ticket/component.html',
  controller: ProductTicketController,
  bindings: {
    product: '<',
    applyBallot: '=',
  },
};

export default ProductTicketComponent;

ProductTicketController.$inject = [
  '$http',
  '$stateParams',
  'LeisureJsonApi',
  'ModalService',
  'ProductRatingService',
];

export function ProductTicketController(
  $http,
  $stateParams,
  JsonApi,
  ModalService,
  ProductRatingService
) {
  const _tickets = {
    locations: {},
    dates: {},
    stock_quantity: null,
  };

  const vm = this;

  let numberOfPurchases = 0;

  vm.ticketsLoading = false;
  vm.filter = null;
  vm.displayMonth = null;
  vm.isBallot = null;
  vm.showFilter = true;

  vm.tickets = {
    locations: {},
    dates: [],
  };

  vm.setMonth = setMonth;
  vm.setFilter = setFilter;
  vm.pickOptions = pickOptions;
  vm.checkStagedRelease = checkStagedRelease;
  vm.$onChanges = onChanges;
  vm.$doCheck = doCheck;

  function onChanges() {
    loadTickets();
    setType();
  }

  function doCheck() {
    if (vm.product.hasOwnProperty('purchased')) {
      const newNumberOfPurchases = vm.product.purchased.length;

      newNumberOfPurchases !== numberOfPurchases && loadTickets();

      numberOfPurchases = newNumberOfPurchases;
    }
  }

  function setType() {
    vm.isBallot = false;

    if (vm.product.hasOwnProperty('features')) {
      vm.isBallot = Object.keys(vm.product.features).indexOf('ballot') !== -1;
    }
  }

  function setMonth(dateString) {
    vm.displayMonth = vm.displayMonth !== dateString ? dateString : null;
  }

  function setFilter(locationId) {
    const tickets = angular.copy(_tickets);
    let dates = Object.keys(tickets.dates).map(key => {
      tickets.dates[key].dateString = key;
      return tickets.dates[key];
    });

    vm.filter = locationId ? locationId : null;

    dates = dates.filter(month => {
      return !vm.filter || month.locationIds.indexOf(vm.filter) !== -1;
    });

    const stock = tickets.stock_quantity;

    tickets.dates = dates;
    tickets.available = !!Object.keys(dates).length && !!stock;

    angular.copy(tickets, vm.tickets);
  }

  function pickOptions(ticket) {
    ProductRatingService.redeem(vm.product.id);
    vm.selectedTicket = ticket;

    const config = vm.isBallot ? ballotPickOptions() : checkoutOptions();

    // show  modal
    ModalService.showModal({
      ...config,
      bodyClass: 'modal-open',
      inputs: {
        product: vm.product,
        variant: ticket,
        tickets: null,
        ballotEntryId: null,
      },
    }).then(modal => {
      modal.close.then(options => !!options && vm.applyBallot(options));
    });
  }

  function ballotPickOptions() {
    return {
      templateUrl: 'app/view/modals/ballot-pick-options.html',
      controller: 'ProductPickOptionsController',
      controllerAs: 'modal',
    };
  }

  function checkoutOptions() {
    return {
      templateUrl: '/app/assets/component/checkout/component.html',
      controller: 'CheckoutModalController',
      controllerAs: 'checkout',
    };
  }

  function loadTickets() {
    vm.ticketsLoading = true;

    $http
      .get(
        `${environment.settings.registeredAwsUrl}/listings/${vm.product.id}/tickets`
      )
      .then(
        response => {
          JsonApi.handleResponse(
            response,
            tickets => {
              angular.copy(tickets, _tickets);

              vm.showFilter = Object.keys(tickets.locations).length > 1;
              setFilter(vm.filter);

              vm.ticketsLoading = false;
            },
            () => (vm.ticketsLoading = false)
          );
        },
        () => (vm.ticketsLoading = false)
      );
  }

  function checkStagedRelease(ticket) {
    const releaseCutOff = moment(ticket.release_date);

    if (moment().isBefore(releaseCutOff.set('hours', 7))) {
      return ` Tickets on sale from ${releaseCutOff.format(
        'dddd DD MMMM'
      )} at 7am`;
    } else if (
      moment().isAfter(releaseCutOff) &&
      moment().isBefore(releaseCutOff.set('hours', 22)) &&
      ticket.stock_quantity <= 0
    ) {
      return `More Tickets on sale from ${releaseCutOff.format(
        'dddd DD MMMM'
      )} at 10pm`;
    } else if (
      ticket.stock_quantity <= 0 &&
      moment().isAfter(releaseCutOff.set('hours', 22))
    ) {
      return 'Sold Out';
    } else {
      return 'Buy Now';
    }
  }
}
