import $ from 'jquery';

window.layout = {};

// window.layout.resize = setFixGuestClass;
window.layout.scroll = setFixedTop;
window.layout.toTop = animateToTop;

let scrolling = false,
  $window = $(window),
  $body = $('body'),
  $brand = $('.brand'),
  // $content = $('.wrap>.content'),
  // $bottomNav = $('.bottom-nav'),
  // navHeight = $bottomNav.height(),
  brandHeight = $brand.height();
// contentHeight = $content.height();

/**
 * Scroll to the top of the page
 * Only start a scroll when
 * the previous has finished
 *
 * @param elementClass
 */
function animateToTop(elementClass) {
  const height = getScrollHeight(elementClass);

  if (!scrolling) {
    scrolling = true;

    $('html, body, #modal').animate({scrollTop: height}, 500);

    setTimeout(() => (scrolling = false), 500);
  }
}

function getScrollHeight(elementClass) {
  const elements = document.querySelectorAll(elementClass);

  return elements.length ? elements[0].offsetTop - brandHeight : 0;
}

function setFixedTop() {
  setBrandHeight();

  $window.scrollTop() >= brandHeight
    ? $body.addClass('fixed-top')
    : $body.removeClass('fixed-top');
}

function setBrandHeight() {
  $brand.length === 0 && ($brand = $('.brand'));

  brandHeight = $brand.height() || 100; // onload we will have null so fix that
}

// function setBottomNavHeight() {
//   $bottomNav.length === 0 && ($bottomNav = $('.brand'));

//   navHeight = $bottomNav.height() || 100; // onload we will have null so fix that
// }

// function setContentHeight() {
//   $content.length === 0 && ($content = $('.wrap >.content'));

//   contentHeight = $content.height() || 500;
// }

/**
 * Load guest content just in time as will not exist onload
 *
 */
// function setFixGuestClass() {
//   setBrandHeight();
//   setContentHeight();
//   setBottomNavHeight();

//   const lowDesktopClass = 'low-ceiling',
//     twoRem =
//       6 * parseFloat(getComputedStyle(document.documentElement).fontSize),
//     height = brandHeight + contentHeight + navHeight + twoRem;

//   if (isGuestPage()) {
//     if (height > $body.height()) {
//       // add style in for desktop
//       !$body.hasClass(lowDesktopClass) && $body.addClass(lowDesktopClass);
//     } else {
//       // remove style for desktop
//       $body.hasClass(lowDesktopClass) && $body.removeClass(lowDesktopClass);
//     }
//   }
// }

// function isGuestPage() {
//   return $('.guest').length;
// }
