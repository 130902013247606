run.$inject = [
  '$rootScope',
  '$timeout',
  '$window',
  'AuthToken',
  '$location',
  'HeadService',
  '$transitions',
];

export default function run(
  $rootScope,
  $timeout,
  $window,
  AuthToken,
  $location,
  Head,
  $transitions
) {
  let currentLocation,
    travellingBackwards = false;

  setAuthToken();
  $transitions.onSuccess({}, transition => {
    const {title} = transition.to();

    title && Head.setTitle(title);
  });

  // These seem to happen in the correct order to track if the page is going bakcwards or not
  // https://stackoverflow.com/questions/15813850/how-to-detect-browser-back-button-click-event-using-angular
  $rootScope.$on(
    '$locationChangeSuccess',
    () => (currentLocation = $location.path())
  );
  $rootScope.$watch(
    () => $location.path(),
    newLocation => (travellingBackwards = currentLocation === newLocation)
  );

  $rootScope.$on('$viewContentLoaded', scrollToPoint);

  function scrollToPoint(event, viewName) {
    try {
      viewName = viewName.viewDecl.$uiViewName;

      /**
       * Map view name to an element to scroll to on the page,
       * If this is non existing it will scroll to the top
       * performs a document.querySelectorAll(elementClass)[0]
       */
      $timeout(() => {
        let element,
          map = {
            results: '.hotel-search-results',
          };

        map.hasOwnProperty(viewName) && (element = map[viewName]);

        // window.layout.resize();

        !travellingBackwards && window.layout.toTop(element);
      }, 500); // allow the action to be performed on the next clock cycle
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  function setAuthToken() {
    if ($window.location.pathname === '/Login') {
      AuthToken.set();
    }
  }
}
