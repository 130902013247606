import './full-width-hero.scss';

const FullWidthHeroComponent = {
  templateUrl: '/app/assets/component/carousel/full-width-hero.html',
  bindings: {
    items: '<',
  },
};

export default FullWidthHeroComponent;
