JsonApiService.$inject = ['MessageService'];

export default function JsonApiService(MessageService) {
  return {
    buildUrl: buildUrl,
    handleResponse: handleResponse,
  };

  function buildUrl(url, filters) {
    url += buildFilters(filters);

    url = url.replace(/([^:]\/)\/+/g, '$1');

    return url;
  }

  function buildFilters(filters) {
    let filterString = '';

    typeof filters !== 'object' && (filters = {});

    angular.forEach(filters, (value, key) => {
      value && (filterString += '/' + key + ':' + value);
    });

    return filterString;
  }

  // Start of api functions
  function handleResponse(response, successFn, cancelFn) {
    let message, success;

    try {
      message = response.data.message;
      success = response.data.status;

      if (success) {
        message && MessageService.success(message);
        successFn(response.data.data);
      } else if (typeof cancelFn === 'function') {
        cancelFn(response.data.data);
      }
    } catch (e) {
      message = e.message;
    }

    if (!success && message) {
      MessageService.error(message);
    }
  }
}
