productRatingService.$inject = [
  '$http',
  'SessionStorageService',
  'LeisureJsonApi',
];

export default function productRatingService($http, StorageService, JsonApi) {
  const httpConfig = {
    ignoreLoadingBar: true,
  };

  const productRatings = {
    show: [],
    view: [],
    redeem: [],
  };

  loadRatings();

  return {
    showList: showProductList,
    show: productId => rateProduct('show', productId),
    view: productId => rateProduct('view', productId),
    redeem: productId => rateProduct('redeem', productId),
  };

  function loadRatings() {
    const {
      show: currentShow,
      view: currentView,
      redeem: currentRedeem,
    } = productRatings;
    const {
      show: savedShow = [],
      view: savedView = [],
      redeem: savedRedeem = [],
    } = StorageService.get('product-ratings') || {};

    productRatings.show = [...currentShow, ...savedShow];
    productRatings.view = [...currentView, ...savedView];
    productRatings.redeem = [...currentRedeem, ...savedRedeem];
  }

  function showProductList(list = []) {
    const {show: currentIds} = productRatings;
    const unratedIds = list.filter(
      productId => !currentIds.includes(productId)
    );

    if (!unratedIds.length) {
      return Promise.resolve();
    }

    return $http
      .post(
        JsonApi.buildUrl(`/product-ratings/show-list`),
        unratedIds,
        httpConfig
      )
      .then(() => saveRatings('show', unratedIds));
  }

  function rateProduct(type, productId) {
    const {[type]: currentIds} = productRatings;

    if (currentIds.includes(productId)) {
      return Promise.resolve();
    }

    return $http
      .post(
        JsonApi.buildUrl(`/product-ratings/${type}`),
        `"${productId}"`,
        httpConfig
      )
      .then(() => saveRatings(type, [productId]));
  }

  function saveRatings(type, productIds = []) {
    const {[type]: currentIds} = productRatings;

    productRatings[type] = [...currentIds, ...productIds];

    StorageService.set('product-ratings', {...productRatings});
  }
}
