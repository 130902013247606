export default function MaxHeightDirective() {
  return {
    scope: {
      maxHeight: '=',
    },
    link,
  };

  function link(scope, element) {
    const delay = 250;
    let [{scrollHeight}] = element;

    let timeout = null;

    setHeight();

    element.on('keydown', () => debounceSetHeight());

    scope.$watch('maxHeight', _maxHeight => setHeight(_maxHeight));

    function debounceSetHeight(_maxHeight = true) {
      !!timeout && clearTimeout(timeout);

      timeout = setTimeout(() => setHeight(_maxHeight), delay);
    }

    function setHeight(_maxHeight = true) {
      scrollHeight = _maxHeight ? element[0].scrollHeight || scrollHeight : 0;

      element.css('max-height', `${scrollHeight}px`);
    }
  }
}
