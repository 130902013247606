productLocationController.$inject = ['close', 'locations', 'available'];

export default function productLocationController(close, locations, available) {
  /** ============================================ *
   * View model
   *  ============================================ */
  const vm = this;

  /** ============================================ *
   * View model : Variables
   *  ============================================ */
  vm.locations = locations;
  vm.title = 'Select Location';
  vm.hide = {
    header: false,
    footer: true,
  };

  /** ============================================ *
   * View model : Functions
   *  ============================================ */

  vm.available = isAvailable;
  vm.close = close;
  vm.submit = submit;

  function isAvailable(variantId) {
    return (
      variantId &&
      (available.indexOf(variantId.toString()) !== -1 ||
        available.indexOf(variantId) !== -1)
    );
  }

  function submit(selected) {
    close(angular.copy(selected), 200);
  }
}
