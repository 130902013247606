import angular from 'angular';

import awsApiService from './aws-api-service';
import DataLayerService from './data-layer-service';
import HeadService from './head-service';
import JsonApiService from './json-api-service';
import LeisureJsonApiService from './leisure-json-api-service';
import SessionStorageService from './session-storage-service';
import StorageService from './storage-service';

export default angular
  .module('PartnerChoice.common.service', [])
  .factory('DataLayerService', DataLayerService)
  .factory('HeadService', HeadService)
  .factory('JsonApi', JsonApiService)
  .factory('LeisureJsonApi', LeisureJsonApiService)
  .factory('SessionStorageService', SessionStorageService)
  .factory('StorageService', StorageService)
  .factory('AwsApiService', awsApiService);
