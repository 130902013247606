const scrollOptions = {
  mouseWheel: true,
  scrollBars: true,
  interactiveScrollbars: true,
};
const scrollPanelOptions = {
  tap: true,
  click: true,
  mouseWheel: true,
  scrollBars: true,
  interactiveScrollbars: true,
};

userPageController.$inject = [
  '$transitions',
  'UserService',
  '$location',
  '$window',
];

export default function userPageController(
  $transitions,
  user,
  gazette,
  $location,
  $window
) {
  const focusElements = {
    top: 'search-input',
    left: null,
    right: null,
    bottom: null,
  };

  /** ============================================ *
   * View Model
   * ============================================= */
  const vm = this;

  /** ============================================ *
   * View Model : variables
   * ============================================= */
  vm.active = {panel: null};
  vm.scrollOptions = scrollOptions;
  vm.scrollPanelOptions = scrollPanelOptions;
  vm.user = user.user;
  vm.gazette = gazette.gazette;

  /**
   * Holds all scroll panel objects
   * @type {{left: null, right: null, top: null, bottom: null}}
   */
  vm.scroll = {
    left: null,
    right: null,
    top: null,
    bottom: null,
  };

  /** ============================================ *
   * View Model : Functions
   * ============================================= */
  vm.bodyClass = activeBodyClass;
  vm.setPanel = setActivePanel;
  vm.goTo = goToLocation;

  /** ============================================ *
   * Private/Protected functions
   * ============================================= */
  function activeBodyClass() {
    return vm.active.panel ? 'active-' + vm.active.panel + '-panel' : null;
  }

  function goToLocation(location, external) {
    if (external) {
      $window.location.href = location;
    } else {
      $location.path(location);
    }
  }

  function setActivePanel(panel) {
    vm.active.panel = vm.active.panel === panel ? null : panel;

    const element = $window.document.getElementById(
      focusElements[vm.active.panel]
    );

    !!element && element.focus();
  }

  /**
   * Global menu close function
   */
  $transitions.onSuccess({}, () => {
    setActivePanel();

    scrollToTop();
  });

  function scrollToTop() {
    // do some scrolling to the top
  }
}
