export default function httpContentInterceptor() {
  return {
    request,
  };

  function request(config) {
    config.headers = {'Content-Type': 'text/plain'};

    return config;
  }
}
