dayFilter.$inject = ['dateFilter'];

export default function dayFilter(dateFilter) {
  return date => {
    let day = dateFilter(date, 'd');

    switch (day) {
      case '1':
      case '21':
      case '31':
        day += 'st';
        break;
      case '2':
      case '22':
        day += 'nd';
        break;
      case '3':
      case '23':
        day += 'rd';
        break;
      default:
        day += 'th';
        break;
    }

    return day;
  };
}
