import './_style.scss';

CheckoutSummaryController.$inject = [];

const CheckoutSummaryComponent = {
  templateUrl: '/app/assets/component/checkout/summary/component.html',
  controller: CheckoutSummaryController,
  bindings: {
    variant: '<',
    summary: '<',
    status: '<',
    isDeliverable: '<',
    setPanel: '&',
  },
};

export default CheckoutSummaryComponent;

export function CheckoutSummaryController() {
  const vm = this;

  vm.prices = {};

  vm.$onInit = $onInit;

  function $onInit() {
    const {
      variant: {prices, supplements},
    } = vm;

    // set prices
    [...prices, ...supplements].forEach(price => {
      vm.prices[price.slug] = price.price;
    });
  }
}
