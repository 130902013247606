guestPageController.$inject = [];

export default function guestPageController() {
  /** ============================================ *
   * View Model
   * ============================================= */
  const vm = this;

  /** ============================================ *
   * View Model : variables
   * ============================================= */
  vm.active = {panel: null};
  vm.guestView = true;

  /**
   * Holds all scroll panel objects
   * @type {{left: null, right: null, top: null, bottom: null}}
   */
  vm.scroll = {
    left: null,
    right: null,
    top: null,
    bottom: null,
  };

  /** ============================================ *
   * View Model : Functions
   * ============================================= */
  vm.bodyClass = activeBodyClass;

  /** ============================================ *
   * Private/Protected functions
   * ============================================= */
  function activeBodyClass() {
    const activeClass = vm.active.panel
      ? 'active-' + vm.active.panel + '-panel'
      : '';
    return 'guest ' + activeClass;
  }
}
