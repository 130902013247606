// Module
var code = `<div class="item-contents">
  <div
    class="image"
    style="background-image:url('/ProductImages/{{ \$ctrl.product.Product.id }}/thumbnail/{{ \$ctrl.product.Product.slug }}image.jpg')"
  ></div>

  <div class="description">
    <h3>{{ \$ctrl.product.Product.title }}</h3>

    <dl>
      <dt
        data-ng-repeat-start="option in \$ctrl.product.order_details.item.options"
      >
        {{ option.qty }} x {{ option.label }}
      </dt>
      <dd data-ng-repeat-end>
        {{ option.total == 0 && 'Free' || (option.total/100 | currency : '£' :
        2) }}
      </dd>

      <dd class="total">
        <strong
          >{{ \$ctrl.product.total_price | currency : '&pound;' : 2 }}</strong
        >
      </dd>
    </dl>
  </div>
  <div class="item-price">
    <p><span class="full">{{ \$ctrl.product.state }} </span></p>
  </div>
</div>
`;
// Exports
export default code;