// Module
var code = `<div id="fade"></div>
<div id="modal" class="jlp-feature-modal">
  <div class="modal-content">
    <div class="modal-container">
      <div class="header" data-ng-hide="false">
        <button type="button" class="close" data-ng-click="modal.close()">
          <span class="fa fa-fw fa-times"></span>
        </button>
        <h3>Toggle features</h3>
      </div>
      <div class="body">
        <p>
          Toggle features on and off and test the latest features before they go
          to production.<br />This is not yet functional.
        </p>

        <section
          class="feature"
          data-ng-repeat="(feature, data) in modal.features"
        >
          <header>{{ feature | ucWords }}</header>
          <description>{{ data.description }}</description>
          <div class="switch round large toggle" data-ng-if="!modal.toggling">
            <input
              id="{{ feature }}"
              type="checkbox"
              data-ng-checked="data.active"
            />
            <label
              for="{{ feature }}"
              data-ng-click="modal.update(feature)"
            ></label>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
`;
// Exports
export default code;