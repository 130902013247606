import environment from '../../../../environment';

loginService.$inject = ['$http', 'LoginTokenService'];

export default function loginService($http, loginTokenService) {
  const service = {
    login,
    logout,
  };

  return service;

  function login(email, password) {
    return $http
      .post(`${environment.settings.loginAwsUrl}/login`, {
        User: {password, email},
      })
      .then(response => {
        const {token, refreshToken} = response.data;
        loginTokenService.updateToken(token);
        loginTokenService.updateRefreshToken(refreshToken);
      });
  }

  function logout() {
    loginTokenService.removeToken();
  }
}
