const ProductListPriceComponent = {
  template: '<p data-ng-bind-html="$ctrl.priceString"></p>',
  controller: ProductListPriceController,
  bindings: {
    product: '<',
  },
};

export default ProductListPriceComponent;

ProductListPriceController.$inject = ['$filter'];
export function ProductListPriceController($filter) {
  const vm = this;

  vm.priceString = '';
  vm.$onChanges = onChanges;

  function onChanges() {
    const {label, value} = vm.product.purchasable
      ? purchasable()
      : nonPurchasable();
    const priceValue = value !== null ? value : '';
    vm.priceString = `<div class="price">${label}</div><span> ${priceValue}</span>`;
  }

  function purchasable() {
    const {from_price: fromPrice} = vm.product;

    const label = 'Partner Price',
      value = fromPrice > 0 ? getPriceString(fromPrice) : 'Free';

    return {
      label,
      value,
    };
  }
  function nonPurchasable() {
    return {
      label: vm.product.price_label || '&nbsp;',
      value: vm.product.price_label_value,
    };
  }
  function getPriceString(price) {
    const fraction = !(price % 100) ? 0 : 2;

    return $filter('currency')(price / 100, '£', fraction);
  }
}
