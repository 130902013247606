import moment from 'moment';

const OdneySummaryComponent = {
  templateUrl: '/app/assets/component/order/odney/component.html',
  controller: OdneySummaryController,
  bindings: {
    odney: '<',
  },
};

export default OdneySummaryComponent;

export function OdneySummaryController() {
  const vm = this;

  vm.$onInit = onInit;

  function onInit() {
    const expiryDate = moment(vm.odney.created).add(1, 'year');

    vm.expiryString =
      expiryDate.diff(moment(), 'days') > 0
        ? `Expires ${expiryDate.format('MMM Do YYYY')}`
        : 'Expired';

    vm.odneyString = `${vm.odney.order_details.itemCount} membership${
      (vm.odney.order_details.itemCount > 1 && 's') || ''
    }`;
  }
}
