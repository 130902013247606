productService.$inject = ['$filter', '$http', 'LeisureJsonApi', 'UserService'];

export default function productService($filter, $http, JsonApi, UserService) {
  let lastUpdated = 0,
    cacheTime = 60 * 60 * 1000, // 1hr in seconds
    allProducts = [],
    filteredProducts = [],
    pageLength = 24;

  const service = {
    filter,
    nextPage,
    items: [],
    limit: {limit: 0},
  };

  return service;

  function filter(expression) {
    return Promise.resolve()
      .then(() => load())
      .then(() => doFilter(expression));
  }

  function doFilter(expression) {
    const orders = ['features.promoted_event', 'features.national', 'title'];

    const orderFilters = [...customOrderBy(), ...orders];

    return Promise.resolve()
      .then(() => (service.limit.limit = 0))
      .then(() =>
        angular.copy(
          $filter('filter')(allProducts, expression),
          filteredProducts
        )
      )
      .then(() =>
        angular.copy(
          $filter('orderBy')(filteredProducts, orderFilters),
          service.items
        )
      )
      .then(() => nextPage());
  }

  function nextPage() {
    morePages() && (service.limit.limit += pageLength);

    return Promise.resolve();
  }

  function morePages() {
    return service.limit.limit < service.items.length;
  }

  /**
   * @todo pull in all items together (products && companies)
   */
  function load() {
    return Promise.resolve()
      .then(() => shouldReload())
      .then(() => resetProducts())
      .then(() => loadProducts())
      .then(() => (lastUpdated = Date.now()))
      .catch(() => angular.noop());
  }

  function shouldReload() {
    return Date.now() > lastUpdated + cacheTime
      ? Promise.resolve()
      : Promise.reject();
  }

  function resetProducts() {
    angular.copy([], allProducts);
  }

  function loadProducts() {
    return $http
      .get(JsonApi.buildUrl('/items'))
      .then(response =>
        JsonApi.handleResponse(response, data =>
          angular.copy(data.Item, allProducts)
        )
      );
  }
  function customOrderBy() {
    const userOrders = [];

    UserService.user.retired && userOrders.push('features.retired');

    return userOrders;
  }
}
