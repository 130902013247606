httpErrorInterceptor.$inject = ['$q', '$window', '$injector', 'Url'];

export default function httpErrorInterceptor($q, $window, $injector, Url) {
  return {responseError};

  function responseError(rejection) {
    const defer = $q.defer();

    401 === rejection.status && forwardToLogin();
    418 === rejection.status && reloadPage();
    449 === rejection.status && loginRoadblock();

    defer.reject(rejection);

    return defer.promise;
  }

  function forwardToLogin() {
    const currentState = $injector.get('$state').current;

    if (currentState.name !== 'Guest.Login') {
      Url.previous = $window.location.pathname;

      $injector.get('$state').go('Guest.Login');
    }
  }

  function reloadPage() {
    $window.location.reload(true);
  }

  function loginRoadblock() {
    $window.location = '/view/roadblock';
  }
}
