const CategoryListComponent = {
  templateUrl: '/app/assets/component/category/component.html',
  controller: CategoryListController,
};

export default CategoryListComponent;

CategoryListController.$inject = [
  '$stateParams',
  '$http',
  'LeisureJsonApi',
  'ProductService',
  'HeadService',
];

export function CategoryListController(
  $stateParams,
  $http,
  JsonApi,
  ProductService,
  Head
) {
  let productsOnPage = () => {};
  // View model
  const vm = this;

  vm.category = {};

  vm.items = ProductService.items;
  vm.limit = ProductService.limit;
  vm.nextPage = ProductService.nextPage;

  vm.$onInit = init;
  vm.registerProductsOnPage = register;

  function init() {
    $http
      .get(JsonApi.buildUrl('Categories/' + $stateParams.category))
      .then(response => JsonApi.handleResponse(response, dealWithCategory));
  }

  function dealWithCategory(data) {
    angular.copy(data.Category, vm.category);

    Head.setTitle(data.Category.title);

    ProductService.filter(
      item => data.Category.itemIds.indexOf(item.id) > -1
    ).then(() => productsOnPage());
  }

  function register(handler) {
    productsOnPage = handler;
  }
}
