import './_styles.scss';

import {tsAndCs} from '../../../../environment/constants';
import disabled from '../product/disabled';

const CheckoutOdneyComponent = {
  templateUrl: '/app/assets/component/checkout/odney/component.html',
  controller: CheckoutOdneyController,
  bindings: {
    close: '&',
    activePanel: '<',
    setOptions: '&',
    product: '<',
    variant: '<',
  },
};

export default CheckoutOdneyComponent;

CheckoutOdneyController.$inject = [];
export function CheckoutOdneyController() {
  const vm = this;

  vm.total = {
    cost: 0,
    items: 0,
  };

  vm.errors = {};

  vm.pickOptionsForm = {};

  vm.items = {};

  vm.showHurry = false;

  vm.tsAndCs = tsAndCs.odney;

  vm.updateTotal = updateTotal;
  vm.disabled = () => disabled(vm);

  vm.$onInit = () => {
    vm.items = vm.variant.prices.reduce((items, price) => {
      items[price.slug] = {
        label: price.label,
        amount: null,
      };

      return items;
    }, {});
  };

  vm.addOptions = () => vm.setOptions({options: vm.items});

  function updateTotal() {
    vm.total.cost = 0;
    vm.total.items = 0;

    vm.variant.prices.forEach(price => {
      if (vm.items.hasOwnProperty(price.slug)) {
        const numberOfItems = vm.items[price.slug].amount;

        vm.total.cost += price.price * numberOfItems;
        vm.total.items += numberOfItems;
      }
    });
  }
}
