export const paymentErrorImage = '/app/assets/img/payment-error.png';

export const statusTypes = {
  cancel: {
    orderTitle: 'You have cancelled your order',
    statusTitle: 'Cancelled',
    orderStatus: 'cancel',
    statusText: 'Your order has been cancelled and no payment will be taken.',
    statusImg: paymentErrorImage,
  },
  success: {
    orderTitle: 'Your order has been successful',
    statusTitle: 'Paid',
    orderStatus: 'success',
    statusImg: '/app/assets/img/payment-success.png',
  },
  failure: {
    orderTitle: 'Your order has failed',
    statusTitle: 'Failed',
    orderStatus: 'failure',
    statusText: 'Your order has failed and no payment will be taken.',
    statusImg: paymentErrorImage,
  },
  pending: {
    orderTitle: 'Your order is being processed',
    statusTitle: 'Processing',
    statusText:
      'Your order is being processed, and the status will be updated shortly.',
    orderStatus: 'pending',
  },
  error: {
    statusTitle: 'Error',
    orderStatus: 'error',
    statusText:
      'There has been an error with your order and no payment will be taken.',
    statusImg: paymentErrorImage,
  },
  statusError: {
    orderTitle: 'An error occurred',
    statusTitle: 'Error',
    orderStatus: 'error',
    statusImg: paymentErrorImage,
  },
};
