const ProductOdneyComponent = {
  templateUrl: '/app/assets/component/product-odney/component.html',
  controller: ProductOdneyController,
  bindings: {
    product: '<',
    applyBallot: '=',
  },
};

export default ProductOdneyComponent;

ProductOdneyController.$inject = [
  '$http',
  'LeisureJsonApi',
  'ModalService',
  'ProductRatingService',
];

export function ProductOdneyController(
  $http,
  JsonApi,
  ModalService,
  ProductRatingService
) {
  const vm = this;

  vm.itemsLoading = false;

  vm.items = [];

  vm.$onInit = onChanges;
  vm.pickOptions = pickOptions;

  function onChanges() {
    loadItems();
  }

  function loadItems() {
    const url = JsonApi.buildUrl(`/v2/registered/odney/${vm.product.id}`);

    vm.itemsLoading = true;

    $http.get(url).then(
      response => {
        JsonApi.handleResponse(
          response,
          product => {
            angular.copy(product.items, vm.items);

            vm.itemsLoading = false;
          },
          () => (vm.itemsLoading = false)
        );
      },
      () => (vm.itemsLoading = false)
    );
  }

  function pickOptions(ticket) {
    ProductRatingService.redeem(vm.product.id);

    // show  modal
    ModalService.showModal({
      templateUrl: '/app/assets/component/checkout/component.html',
      controller: 'CheckoutModalController',
      controllerAs: 'checkout',
      bodyClass: 'modal-open',
      inputs: {
        product: vm.product,
        variant: ticket,
        tickets: null,
        ballotEntryId: null,
      },
    }).then(modal => {
      modal.close.then(options => !!options && vm.applyBallot(options));
    });
  }
}
