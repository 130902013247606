import jwtDecode from 'jwt-decode';
import moment from 'moment';

const tokenStorageName = 'partnerchoice_api_token';
const refreshTokenStorageName = 'partnerchoice_api_refresh_token';

loginTokenService.$inject = ['NewAuthService'];

export default function loginTokenService(NewAuthService) {
  const service = {
    getToken,
    getRefreshToken,
    updateToken,
    updateRefreshToken,
    removeToken,
    isTokenExpired,
    isRefreshTokenExpired,
  };
  return service;

  function getToken() {
    return localStorage.getItem(tokenStorageName);
  }

  function getRefreshToken() {
    return localStorage.getItem(refreshTokenStorageName);
  }

  function updateToken(token) {
    localStorage.setItem(tokenStorageName, token);
    NewAuthService.tokenChanged();
  }

  function updateRefreshToken(token) {
    localStorage.setItem(refreshTokenStorageName, token);
  }

  function removeToken() {
    localStorage.removeItem(tokenStorageName);
    localStorage.removeItem(refreshTokenStorageName);
    NewAuthService.tokenChanged();
  }

  function isTokenExpired() {
    const token = getToken();
    return !validateToken(token);
  }

  function isRefreshTokenExpired() {
    const token = getRefreshToken();
    return !validateToken(token);
  }

  function validateToken(token) {
    try {
      const decoded = token && jwtDecode(token);
      return decoded && moment().isBefore(decoded.exp * 1000);
    } catch {
      return false;
    }
  }
}
