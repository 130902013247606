import './iframe-overlay-modal.scss';

IframeOverlayModalController.$inject = ['close', '$sce', 'iframeUrl'];

export default function IframeOverlayModalController(close, $sce, iframeUrl) {
  const vm = this;

  vm.iframeUrl = $sce.trustAsResourceUrl(iframeUrl);
  vm.budgetClass =
    iframeUrl ===
    'https://partner-tools.moneyadviceservice.org.uk/en/tools/budget-planner';
  vm.close = close;
}
