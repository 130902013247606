CheckoutModal.$inject = [
  '$http',
  'LeisureJsonApi',
  'product',
  'variant',
  'tickets',
  'ballotEntryId',
  'close',
  '$window',
  '$timeout',
  'MessageService',
  '$state',
  'UserService',
  'WorldPayCheckoutLibrary',
];

export default function CheckoutModal(
  $http,
  JsonApi,
  product,
  variant,
  tickets,
  ballotEntryId,
  close,
  $window,
  $timeout,
  Message,
  $state,
  User,
  WorldPayCheckoutLibrary
) {
  let timer = null;
  const iframeTTL = 1000 * 60 * 5;
  const vm = this;

  vm.product = product;
  vm.variant = {supplements: [], ...variant};

  vm.product.isTicket = !!vm.product.features.ticket;

  vm.product.isBallot = !!vm.product.features.ballot;
  vm.product.isOdney = !!vm.product.features.odney;
  vm.product.isProduct = !!vm.product.features.product;
  vm.title = vm.product.title;

  vm.close = () => {
    !!timer && $timeout.cancel(timer);
    close();
  };

  vm.summary = {
    options: {},
    billingAddress: {},
    shippingAddress: {},
    total: 0,
  };

  vm.status = {
    optionsSet: false,
    billingAddressSet: false,
    shippingAddressSet: false,
  };

  vm.isDeliverable = false;

  vm.activePanel = 'options';

  !!tickets && setOptions(tickets);
  init();

  vm.setPanel = setPanel;
  vm.setOptions = setOptions;
  vm.calculateTotal = calculateTotal;
  vm.setBillingAddress = setBillingAddress;
  vm.setShippingAddress = setShippingAddress;
  vm.freePurchase = freePurchase;

  function init() {
    vm.isFree = !vm.variant.prices
      .map(price => price.price)
      .reduce((total, price) => total + price, 0);
  }

  function setOptions(options) {
    const {delivery = {}} = options || {};
    const {amount: hasDelivery = null} = delivery;

    vm.isDeliverable = !!hasDelivery;

    setSummary('options', reduceOptions(options));
    calculateTotal(options);

    const freeNonDeliverable = vm.isFree && !vm.isDeliverable,
      freeDeliverable = vm.isFree && vm.isDeliverable;

    switch (true) {
      case freeNonDeliverable:
        setBillingAddress();
        break;
      case freeDeliverable:
        setBillingAddress();
        vm.activePanel = 'shipping';
        break;
      default:
        vm.activePanel = 'billing';
        break;
    }

    vm.product.isBallot && (vm.status.optionsSet = false);
  }

  function calculateTotal(options) {
    vm.summary.total = 0;
    vm.variant.prices.forEach(price => {
      if (options.hasOwnProperty(price.slug)) {
        const numberOfTickets = options[price.slug].amount;
        vm.summary.total += price.price * numberOfTickets;
      }
    });

    vm.variant.supplements.forEach(supplement => {
      if (options.hasOwnProperty(supplement.slug)) {
        const numberOfSupplements = options[supplement.slug].amount;
        vm.summary.total += supplement.price * numberOfSupplements;
      }
    });
    if (!vm.summary.total > 0) {
      vm.isFree = true;
    }
  }

  function reduceOptions(options) {
    return Object.keys(options)
      .filter(slug => options[slug].amount)
      .reduce((tickets, slug) => {
        tickets[slug] = options[slug];

        return tickets;
      }, {});
  }

  function setBillingAddress(billingAddress) {
    const {first_name: firstName, last_name: surname, email} = User.user;

    setSummary('billingAddress', {
      firstName,
      surname,
      email,
      ...billingAddress,
    });

    switch (true) {
      case vm.isDeliverable:
        vm.activePanel = 'shipping';
        break;
      case vm.isFree:
        doSomethingForFreebies();
        break;
      default:
        getIframeUrl();
        break;
    }
  }

  function doSomethingForFreebies() {
    vm.activePanel = 'free-purchase';
  }

  function setShippingAddress(shippingAddress) {
    const {first_name: firstName, last_name: surname, email} = User.user;

    setSummary('shippingAddress', {
      firstName,
      surname,
      email,
      ...shippingAddress,
    });

    !vm.isFree ? getIframeUrl() : doSomethingForFreebies();
  }

  function freePurchase() {
    registerOrder().then(response =>
      JsonApi.handleResponse(response, ({orderId}) => {
        $state.go('Page.OrderSummary', {orderId, status: 'success'});
      })
    );
  }

  function getIframeUrl() {
    registerOrder()
      .then(response =>
        JsonApi.handleResponse(response, ({redirectUrl, orderId}) => {
          $window.addEventListener('message', handleMessage);
          const url = `${$window.location.protocol}//${$window.location.host}`;

          const customOptions = {
            iframeIntegrationId: 'libraryObject',
            iframeHelperURL: `${url}/worldpay-helper.html`,
            iframeBaseURL: `${url}`,
            type: 'iframe',
            target: 'worldpay-card-details',
            language: 'en',
            country: 'gb',
          };

          const options = {
            ...customOptions,
            url: redirectUrl,
            successURL: `${url}/order/${orderId}/success`, // AUTHORISED
            // pendingURL: `${url}/order/${data.orderId}/pending`, // SHOPPER_REDIRECTED or SENT_FOR_AUTHORISATION - APM only
            failureURL: `${url}/order/${orderId}/failure`, // REFUSED
            cancelURL: `${url}/order/${orderId}/cancel`, // Cancelled by the shopper
            errorURL: `${url}/order/${orderId}/error`, // ERROR
          };

          const WorldPayLibraryObject = new WorldPayCheckoutLibrary.Library();

          WorldPayLibraryObject.setup(options);
          dispatchEvent(new Event('load'));

          setIframeTimer();
        })
      )
      .catch(response => badResponse(response));
  }

  function registerOrder() {
    Object.keys(vm.status).forEach(field => (vm.status[field] = false));

    const options = Object.keys(vm.summary.options).reduce((options, slug) => {
      options[slug] = vm.summary.options[slug].amount;

      return options;
    }, {});

    const order = {
      variantId: vm.variant.id,
      options: options,
      billingAddress: vm.summary.billingAddress,
      priceVersion: vm.variant.features.priceVersion,
    };

    !!Object.keys(vm.summary.shippingAddress).length &&
      (order.shippingAddress = vm.summary.shippingAddress);

    !!ballotEntryId && (order.ballotEntryId = ballotEntryId);

    vm.activePanel = null;

    return $http.post(JsonApi.buildUrl('v2/registered/orders'), order);
  }

  function trashIframe() {
    Message.error('Your payment window has timed out, please try again.', true);

    vm.close();
  }

  function badResponse(response) {
    const message = response.data.message;

    !!message && Message.error(message);

    // close the modal
    vm.close();
  }

  function handleMessage(event) {
    $timeout(() => {
      try {
        const message = JSON.parse(event.data);

        if (message.action === 'resize') {
          vm.activePanel = 'card';
        }
      } catch (e) {
        // errorparsing
      }
    });
  }

  function setPanel(panelName) {
    vm.activePanel = panelName;
  }

  function setSummary(key, value) {
    vm.summary[key] = value;
    vm.status[`${key}Set`] = true;
  }

  function setIframeTimer() {
    !!timer && $timeout.cancel(timer);
    timer = $timeout(trashIframe, iframeTTL);
  }
}
