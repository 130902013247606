import environment from '../../../environment';
import './_product.scss';

const ProductComponent = {
  templateUrl: '/app/assets/component/product/component.html',
  controller: ProductController,
};

export default ProductComponent;

ProductController.$inject = [
  '$http',
  'LeisureJsonApi',
  '$stateParams',
  'HeadService',
  'GlobalStyle',
  'MessageService',
  'ProductRatingService',
];

export function ProductController(
  $http,
  JsonApi,
  $stateParams,
  Head,
  GlobalStyle,
  Message,
  ProductRatingService
) {
  const vm = this;

  vm.applyBallot = applyBallot;

  vm.clearNotify = clearNotify;

  vm.$onInit = init;
  vm.$onDestroy = destroy;
  vm.interact = () => {
    ProductRatingService.redeem(vm.product.id);
  };

  vm.isTicket = false;
  vm.isOdney = false;
  vm.isProduct = false;
  vm.issuuUrl = false;

  function init() {
    vm.product = {};

    vm.mainImageUrl = null;

    vm.soldOut = false;

    // set the global style
    GlobalStyle.setStyle('product');

    load();
  }

  function destroy() {
    GlobalStyle.setStyle();
  }

  function clearNotify() {
    vm.notify = null;
  }

  function applyBallot(order) {
    // fire off to php to record application
    // need to decide how many tickets
    // need to determine which price value tickets
    // must determine if we are allowed this many tickets
    // once ballot entry has created display success modal + set variant applied = true

    // Lets assume all of the above is ok for now
    // this can be implemented at a later date

    return $http
      .post(JsonApi.buildUrl('BallotEntries'), order)
      .then(response => {
        JsonApi.handleResponse(response, showBallotApplyModal);
      });
  }

  function showBallotApplyModal(response) {
    // Show a you have entered pop up
    Message.success('You have successfully applied.');

    // Trigger reload of tickets productTickets.$doChecks
    vm.product.purchased.push(response);
  }

  /**
   * Load the product
   */
  function load() {
    $http
      .get(
        `${environment.settings.registeredAwsUrl}/listing/product-id/${$stateParams.id}`
      )
      .then(response => loadRelatedContent(response));
  }

  function loadRelatedContent(product) {
    $http
      .get(
        `${environment.settings.registeredAwsUrl}/listings/${$stateParams.id}/related-content`
      )
      .then(response =>
        JsonApi.handleResponse(response, productLoad(product, response))
      );
  }
  /**
   * Load the product.promise success function
   * @param product
   */

  function productLoad(productObj, relatedContentObj) {
    const {data: dataProd} = productObj;
    const {data: product} = dataProd;

    const {data: relatedProd} = relatedContentObj;
    const {data: relatedContent} = relatedProd;

    product.ProductHotel &&
      (product.ProductHotel.LocationRule.start = new Date(
        product.ProductHotel.LocationRule.start.date
      ));

    Head.setTitle(product.title);

    angular.copy(product, vm.product);

    vm.isUrl = !!product.features.external_url;
    vm.isFilmology = product.features.filmology;
    vm.isTicket =
      product.features.purchasable &&
      (product.features.ticket || product.features.ballot);
    vm.isOdney = product.features.purchasable && product.features.odney;
    vm.isProduct = product.features.purchasable && product.features.product;
    vm.iframe = product.features.iframe
      ? {title: 'Open Issuu Magazine', ...product.features.iframe}
      : null;
    vm.product.purchased = [];

    vm.mainImageUrl = '/ProductImages/' + vm.product.id;

    !!product.video &&
      vm.product.ProductImage.push({id: 'video', video: product.video});
    ProductRatingService.view(vm.product.id);
    vm.relatedContent = relatedContent;
  }
}
