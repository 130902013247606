import './card.scss';

const CardCarouselComponent = {
  templateUrl: '/app/assets/component/carousel/cards.html',
  controller: 'CarouselController',
  bindings: {
    items: '<',
    overflowScroll: '<',
  },
};

export default CardCarouselComponent;
