modalController.$inject = ['close'];

export default function modalController(close) {
  /** ============================================ *
   * View model
   *  ============================================ */
  const vm = this;

  /** ============================================ *
   * View model : Variables
   *  ============================================ */
  vm.title = 'modal title';
  vm.hide = {
    header: false,
    footer: false,
  };

  vm.display = {
    large: false,
  };

  vm.footer = {
    confirm: 'Confirm',
    disabled: disabled,
  };

  /** ============================================ *
   * View model : Functions
   *  ============================================ */
  vm.close = close;
  vm.confirm = confirm;

  function disabled() {}

  function confirm() {
    close(null, 200);
  }
}
