import './checkout-modal.scss';

import angular from 'angular';

import CheckoutAddressComponent from './address/checkout-address-component';
import CheckoutCardComponent from './card/checkout-card-component';
import CheckoutModal from './checkout-modal';
import CheckoutOdneyComponent from './odney/checkout-odney-component';
import CheckoutOptionComponent from './option/checkout-option-component';
import CheckoutProductComponent from './product/checkout-product-component';
import CheckoutSummaryComponent from './summary/checkout-summary-component';
import TermsComponent from './terms/terms-component';

// eslint-disable-next-line no-undef
const WorldPayCheckoutLibrary = typeof WPCL !== 'undefined' ? WPCL : {};

export default angular
  .module('PartnerChoice.user.checkout', [])
  .constant('WorldPayCheckoutLibrary', WorldPayCheckoutLibrary)
  .controller('CheckoutModalController', CheckoutModal)
  .component('jlpTermsComponent', TermsComponent)
  .component('jlpCheckoutAddressComponent', CheckoutAddressComponent)
  .component('jlpCheckoutCardComponent', CheckoutCardComponent)
  .component('jlpCheckoutOdneyComponent', CheckoutOdneyComponent)
  .component('jlpCheckoutOptionsComponent', CheckoutOptionComponent)
  .component('jlpCheckoutProductsComponent', CheckoutProductComponent)
  .component('jlpCheckoutSummaryComponent', CheckoutSummaryComponent);
