import {BehaviorSubject} from 'rxjs';

import environment from '../../../environment';

export default function FeatureService() {
  const featureBehaviour = new BehaviorSubject(environment.features);
  const service = {
    features: featureBehaviour.asObservable(),
    active,
    toggle,
  };

  return service;

  function toggle(title) {
    const features = featureBehaviour.getValue();

    features[title].active = !features[title].active;

    featureBehaviour.next(features);
  }

  function active(feature) {
    const features = featureBehaviour.getValue();
    return features[feature] && features[feature].active;
  }
}
