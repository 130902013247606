export default function disabled({
  pickOptionsForm,
  total,
  variant,
  terms,
  errors,
}) {
  let disabled = false;

  try {
    formIsValid();

    itemsHaveBeenOrdered();

    enoughTicketsAvailable();

    notTooManyItemsHaveBeenOrdered();

    termsAgreedTo();

    notSetUpErrorsForItems();
  } catch (error) {
    disabled = true;
  }

  return disabled;

  function formIsValid() {
    if (pickOptionsForm.$invalid) {
      throw 'form is invalid';
    }
  }

  function itemsHaveBeenOrdered() {
    const {items} = total;

    if (!items) {
      throw 'no Items ordered';
    }
  }

  function enoughTicketsAvailable() {
    const {items} = total;
    const {stock_quantity} = variant;

    if (items > stock_quantity) {
      throw 'Not enough tickets available';
    }
  }

  function notTooManyItemsHaveBeenOrdered() {
    const {items} = total;
    const {tickets_per_partner} = variant;

    if (tickets_per_partner && items > tickets_per_partner) {
      throw 'To many items have been ordered';
    }
  }

  function termsAgreedTo() {
    if (!terms) {
      throw 'Terms and conditions have not been agreed to';
    }
  }

  function notSetUpErrorsForItems() {
    if (Object.keys(errors).filter(key => errors[key]).length) {
      throw 'Errors exist';
    }
  }
}
