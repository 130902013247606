export default function pressEnter() {
  return {
    restrict: 'A',
    compile: function ($element, attr) {
      return function ngEventHandler(scope, element) {
        element.on('keydown keypress', event => {
          const {which, shiftKey} = event;

          if (which === 13 && shiftKey === false) {
            scope.$apply(() =>
              scope.$eval(attr['pressEnter'], {$event: event})
            );

            event.preventDefault();
          }
        });
      };
    },
  };
}
