import './half-width-hero.scss';

const HalfWidthHeroComponent = {
  templateUrl: '/app/assets/component/carousel/half-width-hero.html',
  bindings: {
    items: '<',
  },
};

export default HalfWidthHeroComponent;
