import './feature-modal-component.scss';

FeatureModalController.$inject = ['close', 'FeatureService', '$timeout'];

export default function FeatureModalController(
  close,
  FeatureService,
  $timeout
) {
  this.close = close;
  this.toggling = false;
  this.features = {};

  FeatureService.features.subscribe(features => {
    Object.keys(features).forEach(t => {
      $timeout(() => {
        this.features[t] = features[t];
      });
    });

    $timeout(() => {
      this.toggling = false;
    });
  });

  this.update = feature => {
    this.toggling = true;
    FeatureService.toggle(feature);
  };
}
