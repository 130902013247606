import modalController from '../../../common/controller/modal-controller';

deleteController.$inject = ['close', 'type'];

export default function deleteController(close, type) {
  modalController.call(this, close);
  const vm = this;

  vm.title = 'Are you sure you want to ' + type + '?';

  vm.confirm = confirm;

  function confirm() {
    close(true, 200);
  }
}
