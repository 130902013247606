productPickOptionsController.$inject = [
  'close',
  'product',
  'variant',
  'tickets',
];

export default function productPickOptionsController(
  close,
  product,
  variant,
  tickets
) {
  /** ============================================ *
   * View model
   *  ============================================ */
  const vm = this;

  /** ============================================ *
   * View model : Variables
   *  ============================================ */

  vm.title = 'Select Options';
  vm.variant = variant;
  vm.product = product;
  vm.total = {
    cost: 0,
    tickets: 0,
  };
  vm.footer = {
    confirm: 'Checkout',
    disabled: disableConfirm,
  };
  vm.tickets = {};
  vm.errors = {};
  vm.tooManySupplements = false;

  vm.pickOptionsForm = {};

  init();

  /** ============================================ *
   * View model : Functions
   *  ============================================ */
  vm.close = close;
  vm.confirm = submit;

  vm.updateTotal = updateTotal;

  function submit() {
    // need to send back separate line for each ticket/price combo
    const response = {
      variant_id: variant.id,
      priceVersion: variant.features.hasOwnProperty('price_version')
        ? variant.features.price_version
        : 0,
      options: vm.tickets,
    };

    close(response, 200);
  }

  function updateTotal() {
    vm.total.cost = 0;
    vm.total.tickets = 0;

    vm.variant.prices.forEach(price => {
      if (vm.tickets.hasOwnProperty(price.slug)) {
        const numberOfTickets = vm.tickets[price.slug];

        vm.total.cost += price.price * numberOfTickets;
        vm.total.tickets += numberOfTickets;
      }
    });

    vm.variant.supplements.forEach(supplement => {
      if (vm.tickets.hasOwnProperty(supplement.slug)) {
        const numberOfSupplements = vm.tickets[supplement.slug];

        vm.total.cost += supplement.price * numberOfSupplements;
      }
    });
  }

  function disableConfirm() {
    let disabled = false;

    try {
      formIsValid();

      ticketsHaveBeenOrdered();

      notTooManyTicketsHaveBeenOrdered();

      enoughTicketsAvailable();

      partnerPriceTicketHasBeenOrdered();

      termsAgreedTo();

      notSetUpErrorsForTickets();

      oneFreeTextPerTicket();
    } catch (e) {
      disabled = true;
    }

    return disabled;

    function formIsValid() {
      if (vm.pickOptionsForm.$invalid) {
        throw 'form is invalid';
      }
    }

    function ticketsHaveBeenOrdered() {
      if (!vm.total.tickets) {
        throw 'no Tickets ordered';
      }
    }

    function notTooManyTicketsHaveBeenOrdered() {
      if (
        variant.tickets_per_partner &&
        vm.total.tickets > variant.tickets_per_partner
      ) {
        throw 'To many tickets have been ordered';
      }
    }

    function enoughTicketsAvailable() {
      if (vm.total.tickets > variant.stock_quantity) {
        throw 'Not enough tickets available';
      }
    }

    function partnerPriceTicketHasBeenOrdered() {
      const partnerPrice = vm.variant.prices[0].slug;

      if (
        !vm.tickets.hasOwnProperty(partnerPrice) ||
        vm.tickets[partnerPrice] <= 0
      ) {
        throw 'No Partner Price tickets have been ordered';
      }
    }

    function termsAgreedTo() {
      if (!vm.terms) {
        throw 'Terms and conditions have not been agreed to';
      }
    }

    function notSetUpErrorsForTickets() {
      if (Object.keys(vm.errors).filter(key => vm.errors[key]).length) {
        throw 'Errors exist';
      }
    }

    function oneFreeTextPerTicket() {
      vm.variant.supplements.forEach(supplement => {
        if (vm.tickets[supplement.slug] > vm.total.tickets) {
          vm.tooManySupplements = true;

          throw 'Can only order 1 free text per ticket';
        }
        vm.tooManySupplements = false;
      });
    }
  }

  function init() {
    // decide if it's a ballot or buy it now
    vm.footer.confirm = isBallot() ? 'Apply' : 'Checkout';

    variant.prices.forEach(price => (vm.tickets[price.slug] = null));
    variant.supplements.forEach(price => (vm.tickets[price.slug] = null));

    vm.tickets = {...vm.tickets, ...tickets};

    updateTotal();
  }

  function isBallot() {
    return product.tags.indexOf('ballot') >= 0;
  }
}
