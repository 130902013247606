import './style.scss';

const TicketSummaryComponent = {
  templateUrl: '/app/assets/component/order/ticket/component.html',
  controller: TicketSummaryController,
  bindings: {
    ticket: '<',
  },
};

export default TicketSummaryComponent;

export function TicketSummaryController() {
  const vm = this;

  vm.$onInit = onInit;

  function onInit() {
    vm.dateString = vm.ticket.Product.ProductVariant.start_date;
    vm.dateString += vm.ticket.Product.ProductVariant.end_date
      ? `-${vm.ticket.Product.ProductVariant.end_date}`
      : '';

    vm.ticketString = `${vm.ticket.total_tickets} ticket${
      (vm.ticket.total_tickets > 1 && 's') || ''
    }`;
  }
}
