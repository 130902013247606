import './_styles.scss';

import {tsAndCs} from '../../../../environment/constants';
import template from './component.html';
import disabled from './disabled';

const CheckoutProductComponent = {
  template,
  controller: CheckoutProductController,
  bindings: {
    close: '&',
    activePanel: '<',
    setOptions: '&',
    product: '<',
    variant: '<',
  },
};

export default CheckoutProductComponent;

CheckoutProductController.$inject = [];

export function CheckoutProductController() {
  const vm = this;

  vm.total = {
    cost: 0,
    items: 0,
  };

  vm.errors = {};

  vm.pickOptionsForm = {};

  vm.items = {};

  vm.showHurry = false;
  vm.linkClicked = false;

  vm.tsAndCs = tsAndCs.product;

  vm.updateTotal = updateTotal;
  vm.disabled = () => disabled(vm);

  vm.$onInit = () => {
    if (
      vm.product.hasOwnProperty('terms_and_conditions') &&
      !!vm.product.terms_and_conditions
    ) {
      vm.tsAndCs = vm.product.terms_and_conditions;
    }

    vm.items = {
      adultPartner: {
        label: vm.variant.title,
        amount: null,
      },
      delivery: {
        label: 'delivery',
        amount: null,
      },
    };

    const stockWarningLevel = 5;
    const {tickets_per_partner, stock_quantity} = vm.variant;

    const noTicketsPerPartner =
      tickets_per_partner === null && stock_quantity < stockWarningLevel;
    const lessStockThanAllowed = stock_quantity < tickets_per_partner;

    vm.showHurry =
      stock_quantity !== null && (noTicketsPerPartner || lessStockThanAllowed);
  };

  vm.addOptions = () => {
    const {adultPartner, delivery} = vm.items;

    const options = {
      adultPartner: {...adultPartner},
      delivery: {...delivery},
    };

    options.delivery.amount === true && (options.delivery.amount = 1);

    vm.setOptions({options});
  };

  function updateTotal() {
    vm.total.cost = 0;
    vm.total.items = 0;

    const {amount: deliveryAmount} = vm.items.delivery;
    const {amount: productAmount} = vm.items.adultPartner;

    const {delivery, price} = vm.variant;

    const deliveryPrice = (deliveryAmount ? delivery : 0) * 1;
    const productPrice = price * productAmount;

    vm.total.cost = deliveryPrice + productPrice;
    vm.total.items = productAmount;
  }
}
