export default function OrderObjectByFilter() {
  return function (items, field, reverse) {
    const filtered = [];

    angular.forEach(items, (item, key) => {
      item.$key = key;
      filtered.push(item);
    });

    filtered.sort((a, b) => {
      return a[field] > b[field] ? 1 : -1;
    });

    if (reverse) {
      filtered.reverse();
    }

    return filtered;
  };
}
