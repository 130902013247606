// Module
var code = `<div id="fade"></div>
<div id="modal" class="video">
  <div class="modal-content">
    <div class="modal-container">
      <div class="header">
        <button type="button" class="close" data-ng-click="Video.close()">
          <span class="fa fa-fw fa-times"></span>
        </button>
      </div>
      <div class="body">
        <iframe
          title="{{Video.title}}"
          type="text/html"
          data-ng-src="{{Video.src}}"
          allow="autoplay;fullscreen;"
        ></iframe>
      </div>
    </div>
  </div>
</div>
`;
// Exports
export default code;